
export interface StockInfo {
    pub_date: string;
    opening_price: string;
    closing_price: string;
    top_price: string;
    low_price: string
    latest_price: string;
    volume: string;
    amount: string;

    date: string;
    time_num: string;
    bingo_result: string;
    bingo_num: number;
    bigsmall: string;
    singledouble: string;
    points: number;
    tail_nums_1: number;
    tail_nums_2: number;
}
  
export type UserInfo = {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string|null;
  name: string;
  password: string;
  rebate: number;
  parent_zhan_cheng: number;
  type: number;
  parent_id: number;
  points: number;
  share: number;
  phone: string;
  stockholder_id: number;
  proxy_id: number;

  stockholder_num: number;
  proxy_num: number;
  player_num: number;
  proxy_points: number;
  player_points: number;
  total_points: number;
  is_sub_account: boolean;
};
export const UserType = {
  admin: 1,
  stockholder: 2,
  agent: 3,
  user: 4,
  adminShare: 5,
}

export type PointsAddsub = {
  user_id: number;
  type: string;
  value: number;
}

export type BetDetail = {
  ID: number;
  CreatedAt: string;
  user_name: string;
  proxy_name: string;
  stockholder_name: string;
  type: string;
  odds: string;
  value: string;
  pub_date: string;
  user_id: number;
  proxy_id: number;
  stockholder_id: number;
  proxy_zhan_percent: string;
  stockholder_zhan_percent: string;
  proxy_zhan: string;
  stockholder_zhan: string;
  proxy_zhan_win: string;
  stockholder_zhan_win: string;
  proxy_rebate_percent: string;
  stockholder_rebate_percent: string;
  proxy_rebate: string;
  stockholder_rebate: string;
  bet_left_points: string;
  opened: boolean;
  bingo_num: number;
  win: string;
  user_new_points: string;
  proxy_new_points: string;
  stockholder_new_points: string;
  player_earn: string;
  proxy_earn: string;
  stockholder_earn: string;
  // boarder_earn: string;
  banker_rebate: string;
  banker_zhan_win: string ;
  banker_earn: string;
  banker_zhan: string;
}

export type ValOfBetType = {
  single: string;
  double: string;
  big: string;
  small: string;
  wei: string;
  tail0: string;
  tail1: string;
  tail2: string;
  tail3: string;
  tail4: string;
  tail5: string;
  tail6: string;
  tail7: string;
  tail8: string;
  tail9: string;
  point0: string;
  point1: string;
  point2: string;
  point3: string;
  point4: string;
  point5: string;
  point6: string;
  point7: string;
  point8: string;
  point9: string;
  point10: string;
  point11: string;
  point12: string;
  point13: string;
  point14: string;
  point15: string;
  point16: string;
  point17: string;
  point18: string;
}

export function UsertypeStr(t: number): string{
  let str = ""
  if(t === UserType.admin){
    str = "管理员"
  }else if (t === UserType.stockholder){
    str = "股东"
  }else if (t === UserType.agent){
    str = "代理"
  }else if (t === UserType.user){
    str = "用户"
  }
  return str
}

export type BetType = 'single' | 'double' | 'big' | 'small' | 'wei' 
    | 'tail0' | 'tail1' | 'tail2' | 'tail3' | 'tail4' | 'tail5' | 'tail6' | 'tail7' | 'tail8' | 'tail9'
    | 'point0' | 'point1' | 'point2' | 'point3' | 'point4' | 'point5' | 'point6' | 'point7' | 'point8' | 'point9'
    | 'point10' | 'point11' | 'point12' | 'point13' | 'point14' | 'point15' | 'point16' | 'point17' | 'point18';
export const BetTypeName: Record<BetType, string> = {
    'single': '单',
    'double': '双',
    'big': '大',
    'small': '小',
    'wei': '围',
    'tail0': '0尾',
    'tail1': '1尾',
    'tail2': '2尾',
    'tail3': '3尾',
    'tail4': '4尾',
    'tail5': '5尾',
    'tail6': '6尾',
    'tail7': '7尾',
    'tail8': '8尾',
    'tail9': '9尾',
    'point0': '0点',
    'point1': '1点',
    'point2': '2点',
    'point3': '3点',
    'point4': '4点',
    'point5': '5点',
    'point6': '6点',
    'point7': '7点',
    'point8': '8点',
    'point9': '9点',
    'point10': '10点',
    'point11': '11点',
    'point12': '12点',
    'point13': '13点',
    'point14': '14点',
    'point15': '15点',
    'point16': '16点',
    'point17': '17点',
    'point18': '18点',
};

export function GetBetTypeName(str: string): string{
  const t = str as BetType
  if (BetTypeName.hasOwnProperty(t)) {
      return BetTypeName[t];
  } else {
      // console.error("invalid type");
      return ""
  }
}

export type ReportByUserDetail = {
  time_num: string;
  user_id: number;
  name: string;
  points: string;
  player_total_points: string;
  proxy_total_points: string;
  bet_number: number;
  value: string;
  banker_rebate: string;
  banker_earn: string;
  banker_zhan_win: string;
  player_earn: string;
  proxy_earn: string;
  stockholder_earn: string;
  proxy_rebate: string;
  stockholder_rebate: string;
  proxy_zhan: string;
  stockholder_zhan: string;
  proxy_zhan_win: string;
  stockholder_zhan_win: string;
  higher_level_earn: string;
}
export type AdminshareReportDetail = {
  date_day: string;
  pub_date: string;
  value: string;
  bet_number: number;
  banker_zhan: string;
  banker_rebate: string;
  banker_earn: string;
  banker_zhan_win: string;
}

export type ReportAdminshare = {
  list: AdminshareReportDetail[];
  total: AdminshareReportDetail;
}

export type ReportByUser = {
  list: ReportByUserDetail[];
  total: ReportByUserDetail;
}

export type BetSumDetail = {
  type: string;
  value: string;
  bet_number: number;
  player_earn: string;
  proxy_earn: string;
  proxy_rebate: string;
  proxy_zhan: string;
  proxy_zhan_win: string;
  stockholder_earn: string;
  stockholder_rebate: string;
  stockholder_zhan: string;
  stockholder_zhan_win: string;
  banker_rebate: string;
  banker_zhan: string;
  banker_zhan_win: string;
  banker_earn: string;
}

export type BetSum = {
  bingo_num: number;
  time: string;
  list: BetSumDetail[];
  total: BetSumDetail;
}

export type BillDetail = {
  id: number;
  created_at: string;
  update_by: number;
  update_by_name: string;
  user_id: number;
  user_name: string;
  type: string;
  value: string;
  old_points: string;
  new_points: string;
}

export type ChildReportMap = {
  [id: string]: ReportByUser,
}
export type ChildUserlistMap = {
  [id: string]: UserInfo,
}

export type BettingStatInfo = {
  dest: string;
  stat: number;
  stock: StockInfo;
  sec_left: number;
  history: ValOfBetType;
}

export const BettingStatType = {
  betting: 1,
  openning: 2,
  opened: 3,
  closed: 4,
}