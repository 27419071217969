import React, { useState } from 'react';
import { Card } from 'antd';
import OddsSettings from './OddsSettings';
import '../../App.css'
import OtherSettings from './OtherSettings';
import BetLimitSettings from './BetLimitSettings';
import TotalBetLimitSettings from './TotalBetLimitSettings';

const tablist = [
  { key: 'oddsSettings', label: '赔率设置' },
  { key: 'betlimitSettings', label: '限额设置' },
  { key: 'totalBetlimitSettings', label: '风控设置(总限额)' },
  { key: 'otherSettings', label: '其他设置' },
];

const Settings: React.FC = () => {
  let tabList = tablist
  const [activeTabKey, setActiveTabKey] = useState<string>(tabList[0].key);

  const onTabChange = async (key: string) => {
    setActiveTabKey(key);
  };

  const contentList: Record<string, React.ReactNode> = {
    oddsSettings: <OddsSettings></OddsSettings>,
    betlimitSettings: <BetLimitSettings></BetLimitSettings>,
    totalBetlimitSettings: <TotalBetLimitSettings></TotalBetLimitSettings>,
    otherSettings: <OtherSettings></OtherSettings>,
  };

  return (
    <>
      <Card
        className='user-card'
        style={{ width: '100%', minHeight: '600px' }}
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        tabProps={{
          size: 'middle',
        }}
      >
        {contentList[activeTabKey]}
      </Card>
    </>
  );
};

export default Settings;