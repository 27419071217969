import './App.css';
import { UserProvider } from './components/user/AuthContext';
import MyLayout from './components/Layout'
import { App } from "antd";

function MainApp() {

  return (
    <div className="App">
      <App>
        <UserProvider>
          <MyLayout></MyLayout>
        </UserProvider>
      </App>
    </div>
  );
}

export default MainApp;
