import { useState, useEffect, useContext } from 'react';
import '../../App.css';
import { Form, Button, Flex, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import apis from '../../apis';
import { UserContext } from '../user/AuthContext';
import { BetDetail, UserType } from '../../utils/types';
import BetList from './BetList';
import UserSelectForm from './UserSelectForm';

function BetHistory() {
    const [form] = Form.useForm();
    const context = useContext(UserContext);
    const [tabledata, setTabledata] = useState<BetDetail[]>([]);
    const updateData = async (uid: number, pid: number, sid: number, s: number, e: number) => {
        if (context.user.type === UserType.stockholder) {
            sid = context.user.ID
        } else if (context.user.type === UserType.agent) {
            pid = context.user.ID
        } else if (context.user.type === UserType.user) {
            uid = context.user.ID
        }
        const res = await apis.getBetList(uid, pid, sid, s, e, 100)
        if (res.failed) {
            console.error("获取投注列表失败")
            return
        }
        setTabledata(res.data)
    }

    const doSearch = async () => {
        // 获取表单的值
        const values = await form.validateFields();
        let start = values.start_time;
        let end = values.end_time;
        let sid = values.sid;
        let uid = values.uid;
        let pid = values.pid;

        console.log("start:", start)
        console.log("end:", end)
        // 将 dayjs 对象转换为字符串
        let startTimestamp = 0;
        let endTimestamp = 0;
        if (start) {
            startTimestamp = start.unix()
        }
        if (end) {
            endTimestamp = end.unix()
        }
        // 发送查询请求updateData
        await updateData(uid, pid, sid, startTimestamp, endTimestamp)
    };
    const handleSearch = debounce(doSearch, 500)

    useEffect(() => {
        updateData(0, 0, 0, 0, 0);
    }, []);

    return (
        <Flex gap="middle" vertical>
            <Flex wrap="wrap" gap="" align='center'>
                <Form form={form}
                    layout="inline"
                    initialValues={{
                        start_time: null,
                        end_time: null,
                    }}
                >
                    <Form.Item
                        name="start_time"
                        label="开始时间"
                        rules={[{ required: false, message: '请选择开始时间' }]}
                    >
                        <DatePicker showTime needConfirm={false} format="YYYY-MM-DD HH:mm" placeholder="开始时间" />
                    </Form.Item>
                    <Form.Item
                        name="end_time"
                        label="结束时间"
                        rules={[{ required: false, message: '请选择结束时间' }]}
                    >
                        <DatePicker showTime needConfirm={false} format="YYYY-MM-DD HH:mm" placeholder="结束时间" />
                    </Form.Item>
                    <UserSelectForm></UserSelectForm>
                </Form>
            </Flex>
            <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch}>查询</Button>
            <BetList data={tabledata}></BetList>
        </Flex>
    );
}

export default BetHistory;
