import { useEffect } from 'react';
import { Form, Row, Col, Divider, App } from 'antd';
import apis from '../../apis';
import { Button, type FormProps, InputNumber } from 'antd';
import '../../App.css'
import { ValOfBetType } from '../../utils/types';


function TotalBetLimitSettings() {
    const { message } = App.useApp();
    const [betlimitForm] = Form.useForm();
    const onFinish: FormProps<ValOfBetType>["onFinish"] = async (values) => {
        const res = await apis.updateTotalBetlimitSettings(values)
        if (res.failed) {
            message.error("更新失败:" + res.error)
            return
        }
        message.success("更新成功")
    };

    const onFinishFailed: FormProps<ValOfBetType>["onFinishFailed"] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        const updateData = async () => {
            const res = await apis.getSettings()
            if (res.failed) {
                message.error("更新失败:" + res.error)
                return
            }
            betlimitForm.setFieldsValue(res.data.total_bet_limit)
        }

        updateData()
        return () => {
            betlimitForm.resetFields();
        };
    },[])

    return (
        <>
            <Form
                // style={isChecked?{backgroundColor:'orange'}:{}}
                form={betlimitForm}
                labelCol={{ span: 8, sm: { span: 8 }, xs: { span: 11 } }}
                wrapperCol={{ span: 16, sm: { span: 16 }, xs: { span: 13 } }}
                // colon={false}
                // style={{ maxWidth: 600 }}
                // initialValues= {odds}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Row gutter={12}>
                    <Col span={8} >
                        <Divider >单双围</Divider>
                        <Row>
                            <Form.Item<ValOfBetType> name={"single"} label="单" ><InputNumber formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} controls={false} min={'0'} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                        <Row>
                            <Form.Item<ValOfBetType> name={"double"} label="双" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                        <Row>
                            <Form.Item<ValOfBetType> name={"big"} label="大" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                        <Row>
                            <Form.Item<ValOfBetType> name={"small"} label="小" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                        <Row>
                            <Form.Item<ValOfBetType> name={"wei"} label="围" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>
                    <Col span={16} >
                        <Divider >平特尾</Divider>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Row>
                                    <Form.Item<ValOfBetType> name={"tail0"} label="0尾" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item<ValOfBetType> name={"tail1"} label="1尾" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item<ValOfBetType> name={"tail2"} label="2尾" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item<ValOfBetType> name={"tail3"} label="3尾" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item<ValOfBetType> name={"tail4"} label="4尾" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Form.Item<ValOfBetType> name={"tail5"} label="5尾" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item<ValOfBetType> name={"tail6"} label="6尾" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item<ValOfBetType> name={"tail7"} label="7尾" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item<ValOfBetType> name={"tail8"} label="8尾" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                                </Row>
                                <Row>
                                    <Form.Item<ValOfBetType> name={"tail9"} label="9尾" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Divider style={{ marginTop: 0 }}>点数</Divider>
                <Row gutter={12}>
                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point0"} label="0点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point1"} label="1点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point2"} label="2点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point3"} label="3点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point4"} label="4点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point5"} label="5点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point6"} label="6点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point7"} label="7点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point8"} label="8点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point9"} label="9点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point10"} label="10点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point11"} label="11点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point12"} label="12点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point13"} label="13点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point14"} label="14点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point15"} label="15点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point16"} label="16点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point17"} label="17点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row>
                            <Form.Item<ValOfBetType> name={"point18"} label="18点" ><InputNumber controls={false} min={'0'} formatter={(val) => Math.floor(parseFloat(val || "0") * 100) / 100 + ""} stringMode className='betsetting-input-number'></InputNumber></Form.Item>
                        </Row>
                    </Col>

                </Row>
                <Form.Item wrapperCol={{ span: 22 }} style={{ textAlign: "right" }}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}


export default TotalBetLimitSettings;