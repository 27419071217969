import {  App, Button } from 'antd';
import apis from '../../apis';
import { UserInfo } from '../../utils/types';
import '../../App.css';

function UserDelModal({data, onOk}:{data: UserInfo, onOk: ()=>any}) {
    const { message, modal } = App.useApp();


    const showModal = () => {
        modal.confirm({
            title: "删除用户",
            content: "即将删除用户：" + data.name,
            onOk: async () => {
                const res = await apis.delUser(data.ID)
                if (res.failed) {
                    message.error("加减分数失败:" + res.error)
                    return
                }
                onOk()
            }
        })
    }

    return (
        <>
            {/* <Button type="text" className='linkbtn' onClick={showModal}>删除</Button> */}
        </>
    )
}


export default UserDelModal;