import { Table } from "antd";
import { BetSum, GetBetTypeName, UserType, ValOfBetType } from "../../utils/types";
import Column from "antd/es/table/Column";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../user/AuthContext";
import { FormatMoney } from "../Utils";
import ValueOfTypes from "../ValueOfTypes";
import "./BetCss.css"

function BetSumComp({ data }: { data: BetSum }) {
    const context = useContext(UserContext)
    const [dataoftype, setdataoftype] = useState<ValOfBetType>({} as ValOfBetType);

    useEffect(() => {
        let res = {} as ValOfBetType
        if (data && data.list.length > 0) {
            for (let obj of data.list) {
                if (context.user.type === UserType.agent) {
                    res[obj.type as keyof ValOfBetType] = obj.proxy_zhan
                } else if (context.user.type === UserType.stockholder) {
                    res[obj.type as keyof ValOfBetType] = obj.stockholder_zhan
                } else if (context.user.type === UserType.admin || UserType.adminShare) {
                    res[obj.type as keyof ValOfBetType] = obj.banker_zhan
                }
            }
        }
        console.log(data)
        setdataoftype(res)
    }, [data]);
    return (
        <>
            <p className="timeNumAndBingNum">
                <span>期数：</span><span className="timeNum">{moment.utc(data.time).format('MM-DD HHmm')}</span>
                {data.bingo_num && 
                <>
                    <span>开奖号码：</span>
                    <span className="bingNum">{data.bingo_num.toFixed().padStart(2, '0')}</span>
                </>
                }
            </p>
            <Table dataSource={data.list} rowKey={record => record.type}
                scroll={{ x: 'max-content' }}
                pagination={false}
                summary={(pageData) => {
                    // let totalBorrow = 0;
                    // let totalRepayment = 0;

                    // pageData.forEach(({ borrow, repayment }) => {
                    // totalBorrow += borrow;
                    // totalRepayment += repayment;
                    // });

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <span>{data.total.bet_number}</span>
                                </Table.Summary.Cell>

                                {(context.user.type === UserType.admin || context.user.type === UserType.adminShare) ?
                                <>
                                    <Table.Summary.Cell index={2}>
                                        <span>{data.total.banker_zhan}</span>
                                    </Table.Summary.Cell>
                                </>
                                :
                                <>
                                    <Table.Summary.Cell index={2}>
                                        <span>{data.total.value}</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>
                                        {FormatMoney(data.total.player_earn)}
                                    </Table.Summary.Cell>
                                </>
                                }

                                {context.user.type === UserType.agent &&
                                    <>
                                        <Table.Summary.Cell index={4}>
                                            {FormatMoney(data.total.proxy_rebate)}
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>
                                            {FormatMoney(data.total.proxy_zhan_win)}
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}>
                                            {FormatMoney(data.total.proxy_earn)}
                                        </Table.Summary.Cell>
                                    </>
                                }
                                {context.user.type === UserType.stockholder &&
                                    <>
                                        {/* <Table.Summary.Cell index={4}>
                                            {FormatMoney(data.total.proxy_rebate)}
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>
                                            {FormatMoney(data.total.proxy_zhan_win)}
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}>
                                            {FormatMoney(data.total.proxy_earn)}
                                        </Table.Summary.Cell> */}
                                        <Table.Summary.Cell index={7}>
                                            {FormatMoney(data.total.stockholder_rebate)}
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={8}>
                                            {FormatMoney(data.total.stockholder_zhan_win)}
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={9}>
                                            {FormatMoney(data.total.stockholder_earn)}
                                        </Table.Summary.Cell>
                                    </>
                                }
                                {(context.user.type === UserType.admin || context.user.type === UserType.adminShare) &&
                                    <>
                                        {/* <Table.Summary.Cell index={7}>
                                {FormatMoney(data.total.stockholder_rebate)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={8}>
                                {FormatMoney(data.total.stockholder_zhan_win)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={9}>
                                {FormatMoney(data.total.stockholder_earn)}
                            </Table.Summary.Cell> */}
                                        <Table.Summary.Cell index={7}>
                                            {FormatMoney(data.total.banker_rebate)}
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={8}>
                                            {FormatMoney(data.total.banker_zhan_win)}
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={9}>
                                            {FormatMoney(data.total.banker_earn)}
                                        </Table.Summary.Cell>
                                    </>
                                }
                            </Table.Summary.Row>
                        </>
                    );
                }}
            >
                <Column title="类型" dataIndex="type" key="type" render={(t) => GetBetTypeName(t)} />
                <Column title="笔数" dataIndex="bet_number" key="bet_number" />

                {(context.user.type === UserType.admin || context.user.type === UserType.adminShare) ?
                <>
                    <Column title="庄家占" dataIndex="banker_zhan" key="banker_zhan" />
                </>
                :
                <>
                    <Column title="金额" dataIndex="value" key="value" />
                    <Column title="玩家盈利" dataIndex="player_earn" key="player_earn" render={FormatMoney} />
                </>
                }

                {context.user.type === UserType.agent &&
                    <>
                        <Column title="代理水" dataIndex="proxy_rebate" key="proxy_rebate" render={FormatMoney} />
                        <Column title="代理占输赢" dataIndex="proxy_zhan_win" key="proxy_zhan_win" render={FormatMoney} />
                        <Column title="代理盈利" dataIndex="proxy_earn" key="proxy_earn" render={FormatMoney} />
                    </>
                }
                {context.user.type === UserType.stockholder &&
                    <>
                        {/* <Column title="代理水" dataIndex="proxy_rebate" key="proxy_rebate" render={FormatMoney} />
                        <Column title="代理占输赢" dataIndex="proxy_zhan_win" key="proxy_zhan_win" render={FormatMoney} />
                        <Column title="代理盈利" dataIndex="proxy_earn" key="proxy_earn" render={FormatMoney} /> */}
                        <Column title="股东水" dataIndex="stockholder_rebate" key="stockholder_rebate" render={FormatMoney} />
                        <Column title="股东占输赢" dataIndex="stockholder_zhan_win" key="stockholder_zhan_win" render={FormatMoney} />
                        <Column title="股东盈利" dataIndex="stockholder_earn" key="stockholder_earn" render={FormatMoney} />
                    </>
                }
                {(context.user.type === UserType.admin || context.user.type === UserType.adminShare) &&
                    <>
                        <Column title="庄家水" dataIndex="banker_rebate" key="banker_rebate" render={FormatMoney} />
                        <Column title="庄家占输赢" dataIndex="banker_zhan_win" key="banker_zhan_win" render={FormatMoney} />
                        <Column title="盈利" dataIndex="banker_earn" key="banker_earn" render={FormatMoney} />
                    </>
                }
            </Table>

            <ValueOfTypes data={dataoftype}></ValueOfTypes>
        </>
    )
}

export default BetSumComp