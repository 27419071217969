import '../../App.css';
import { Table } from 'antd';
import { ReportAdminshare } from '../../utils/types';
import Column from 'antd/es/table/Column';
import { FormatMoney } from '../Utils';
import moment from 'moment';

function AdminShareReport({ data }: { data?: ReportAdminshare}) {
    return (
        <>
            {data &&
                <Table dataSource={data.list} rowKey={record => record.date_day}
                    scroll={{ x: 'max-content' }}
                    // expandable={{
                    //     rowExpandable: (record) => record.name !== 'Not Expandable',
                    // }}
                    summary={(pageData) => {
                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={1}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>
                                        <span>{data.total.bet_number}</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>
                                        <span>{data.total.value}</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>
                                        {FormatMoney(data.total.banker_zhan)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>
                                        {FormatMoney(data.total.banker_rebate)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>
                                        {FormatMoney(data.total.banker_zhan_win)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>
                                        {FormatMoney(data.total.banker_earn)}
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }}
                >
                    <Column title="日期" dataIndex="date_day" key="date_day" />
                    <Column title="期数" dataIndex="pub_date" key="pub_date" render={(t) => moment.utc(t).format('MM-DD HHmm')}  />
                    <Column title="笔数" dataIndex="bet_number" key="bet_number" />
                    <Column title="下注额" dataIndex="value" key="value" />
                    <Column title="庄占成" dataIndex="banker_zhan" key="banker_zhan" />
                    <Column title="庄反水" dataIndex="banker_rebate" key="banker_rebate" render={FormatMoney}/>
                    <Column title="庄家占输赢" dataIndex="banker_zhan_win" key="banker_zhan_win" render={FormatMoney}/>
                    <Column title="庄盈利" dataIndex="banker_earn" key="banker_earn" render={FormatMoney}/>
                </Table>
            }
        </>
    );
}

export default AdminShareReport;
