import { useContext } from 'react';
import { Button, Form, Input, InputNumber, FormProps, App } from 'antd';
import apis from '../../apis';
import { UserInfo, UserType } from '../../utils/types';
import { UserContext } from './AuthContext';
import '../../App.css';

function UserEditModal( {data, onOk}:{data: UserInfo, onOk: ()=>any} ) {
    const { message, modal } = App.useApp();
    const context = useContext(UserContext);
    const titleType = () => {
        let str = "用户"
        if (context.user.type === UserType.admin) {
            str = "股东"
        }
        if (context.user.type === UserType.stockholder) {
            str = "代理"
        }
        return str
    }
    const userType = () => {
        let t = UserType.user
        if (context.user.type === UserType.admin) {
            t = UserType.stockholder
        }
        if (context.user.type === UserType.stockholder) {
            t = UserType.agent
        }
        return t
    }
    const [usereditForm] = Form.useForm();

    const submit = async () => {
        const values = await usereditForm.validateFields();
        values.type = userType();
        const res = await apis.updateUser(values)
        if (res.failed) {
            message.error("更新用户失败:" + res.error)
            return
        }
        onOk()
    };

    const content =
        <Form
            form={usereditForm}
            // name="basic"
            initialValues={data}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            autoComplete="off"
        >
            <Form.Item<UserInfo>
                label="用户名"
                name="name"
                rules={[{ required: true, message: '请输入用户名' }, { max: 10, message: '用户太长' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item<UserInfo>
                label="id"
                name="ID"
                hidden={true}
            >
                <Input />
            </Form.Item>

            <Form.Item<UserInfo>
                label="密码"
                name="password"
            >
                <Input.Password />
            </Form.Item>
            {context.user.type === UserType.admin &&
                <Form.Item<UserInfo>
                    label="股份"
                    name="share"
                    initialValue={0}
                >
                    <InputNumber min={0} max={100} placeholder='最大100' style={{ width: '100%' }} />
                </Form.Item>
            }
            {context.user.type !== UserType.agent &&
                <Form.Item<UserInfo>
                    label="反水"
                    name="rebate"
                // initialValue={editUser.rebate}
                >
                    <InputNumber min={0} max={3} placeholder='最大3' style={{ width: '100%' }} />
                </Form.Item>
            }
            {context.user.type !== UserType.admin &&
                <Form.Item<UserInfo>
                    label="占成"
                    name="parent_zhan_cheng"
                    initialValue={0}
                >
                    <InputNumber min={0} 
                        max={ context.user.type === UserType.agent?30:50 }
                        style={{ width: '100%' }} />
                </Form.Item>
            }
        </Form>

    const showModal = () => {
        modal.info({
            okText: "提交",
            onOk: submit,
            title: "编辑" + titleType(),
            closable: true,
            maskClosable: true,
            content: content,
        })    
    }

    return (
         <Button type="text" className='linkbtn' onClick={showModal}>编辑</Button>
    )
}

export default UserEditModal;