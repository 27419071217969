import React, { useState } from 'react';
import { Card } from 'antd';
import '../../App.css'
import BetHistory from './BetHistory';
import BetSumHistory from './BetSumHistory';
import BetSumRealtime from './BetSumRealtime';

const tabList = [
    { key: 'realtimeSum', label: '实时汇总' },
    { key: 'historySum', label: '历史汇总' },
    { key: 'history', label: '历史明细' },
  ];
const BetRecord: React.FC = () => {
  const [activeTabKey, setActiveTabKey] = useState<string>(tabList[0].key);

  const onTabChange = async (key: string) => {
    setActiveTabKey(key);
  };

  const contentList: Record<string, React.ReactNode> = {
    realtimeSum: <BetSumRealtime />,
    historySum: <BetSumHistory />,
    history: <BetHistory />,
  };

  return (
    <>
      <Card
        className='user-card'
        style={{ width: '100%' }}
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        tabProps={{
          size: 'middle',
        }}
      >
        {contentList[activeTabKey]}
      </Card>
    </>
  );
};

export default BetRecord;