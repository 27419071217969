import React, { createContext, useState, ReactNode } from 'react';
import { BettingStatInfo, UserInfo } from '../../utils/types';
import apis from '../../apis';
import { debounce } from 'lodash';

type props = {
  user: UserInfo,
  setUser: React.Dispatch<React.SetStateAction<UserInfo>>,
  updateUser: (isSubAccount:boolean, id?:number)=>void,
  bettingStat: BettingStatInfo,
  setBettingStat: React.Dispatch<React.SetStateAction<BettingStatInfo>>,
  // isopen: boolean,
  // setisopen: React.Dispatch<React.SetStateAction<boolean>>,
}
export const UserContext = createContext<props>({
  user: {} as UserInfo,
  setUser: () => { },
  updateUser: ()=>{},
  // isopen: false,
  // setisopen: () => { },
  bettingStat: {} as BettingStatInfo,
  setBettingStat: ()=>{},
});

interface UserProviderProps {
  children: ReactNode;
}

// 创建一个 Provider 组件
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserInfo>({} as UserInfo);
  const [bettingStat, setBettingStat] = useState<BettingStatInfo>({} as BettingStatInfo);
  const doUpdateUser = async(isSubAccount:boolean, id?: number)=>{
    if(!id){
      id = user.ID
    }
    if(id === 0){
      return 
    }
    const res = await apis.getUser(id)
    if(res.failed){
      console.error("获取用户信息失败")
      return
    }
    if(res.data){
      res.data.is_sub_account = isSubAccount;
    }

    console.log("res: ", res.data)
    setUser(res.data)
  }
  const updateUser = debounce(doUpdateUser, 1000 )
  return (
    <UserContext.Provider value={{ user, setUser, updateUser, bettingStat, setBettingStat}}>
      {children}
    </UserContext.Provider>
  );
};