import { useState, useEffect, useContext } from 'react';
import '../../App.css';
import { Form, Button, Flex, DatePicker, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import apis from '../../apis';
import BetSumComp from './BetSumComp';
import { UserContext } from '../user/AuthContext';
import { UserType } from '../../utils/types';
import UserSelectForm from './UserSelectForm';

function BetSumHistory() {
  const [tabledata, settabledata] = useState();
  const context = useContext(UserContext)
  const [form] = Form.useForm();

  const doSearch = async () => {
    // 获取表单的值
    const values = await form.validateFields();
    let start = values.start_time;
    let sid = values.sid;
    let uid = values.uid;
    let pid = values.pid;
    // 将 dayjs 对象转换为字符串
    let startTimestamp = 0;
    if(start){
      startTimestamp = start.unix()
    }
    if(context.user.type === UserType.stockholder){
      sid = context.user.ID
    }else if(context.user.type === UserType.agent){
      pid = context.user.ID
    }
    // 发送查询请求
    const res = await apis.getBetsum(uid, pid, sid, startTimestamp)
    if(res.failed){
      console.log(res.error)
      return
    }
    settabledata(res.data)
  };
  const handleSearch = debounce(doSearch, 500)

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <Flex gap="middle" vertical>
      <Flex wrap="wrap" gap="" align='center'>
        <Form form={form}
          layout="inline"
          initialValues={{
            start_time: null,
          }}
        >
          <Form.Item
            name="start_time"
            label="时间"
            rules={[{ required: false, message: '请选择时间' }]}
          >
            <DatePicker showTime needConfirm={false} format="YYYY-MM-DD HH:mm" placeholder="时间" />
          </Form.Item>
          {
            context.user.type !== UserType.adminShare && 
            <UserSelectForm></UserSelectForm>
          }
        </Form>
      </Flex>
      <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch}>查询</Button>
      {tabledata && <BetSumComp data={tabledata}></BetSumComp>}
    </Flex>
  );
}

export default BetSumHistory;
