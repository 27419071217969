import { useState, useEffect, useRef } from 'react';
import '../../App.css';
import { Table, TableProps, Modal, TablePaginationConfig } from 'antd';
import moment from 'moment';
import { StockInfo } from '../../utils/types';

function StocksList({ stocksdata, pagination }: { stocksdata?: StockInfo[], pagination?: false | TablePaginationConfig | undefined }) {
  const [detaildata, setdetaildata] = useState<StockInfo>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dataRef = useRef<StockInfo[]>();
  useEffect(() => {
    dataRef.current = stocksdata;
  }, [stocksdata])

  const columns: TableProps<StockInfo>['columns'] = [
    {
      title: '期数',
      width: 100,
      dataIndex: 'pub_date',
      key: 'pub_date',
      render: (t) => moment.utc(t).format('MM-DD HHmm'),
    },
    // {
    //   title: '期数',
    //   dataIndex: 'time_num',
    //   key: 'time_num',
    // },
    {
      title: '开奖结果',
      dataIndex: 'bingo_result',
      key: 'bingo_result',
      render: (v) => formatNum(v)
    },
    {
      title: '奖号',
      width: 60,
      dataIndex: 'bingo_num',
      key: 'bingo_num',
      render: (v) => v.toFixed().padStart(2, '0')
    },
    {
      title: '大小',
      width: 40,
      dataIndex: 'bigsmall',
      key: 'bigsmall',
      onCell: (r) => {
        let v = r.bigsmall
        if (!v) {
          return {}
        }
        let color = 'orange';
        if (v !== '围') {
          if (v === '大') {
            color = 'cadetblue'
          } else {
            color = 'lightblue'
          }
        }

        return {
          style: { backgroundColor: color },
          children: v,
        }
      }
    },
    {
      title: '单双',
      width: 40,
      dataIndex: 'singledouble',
      key: 'singledouble',
      onCell: (r) => {
        let v = r.singledouble
        if (!v) {
          return {}
        }
        let color = 'orange';
        if (v !== '围') {
          if (v === '双') {
            color = 'darkgray'
          } else {
            color = 'white'
          }
        }

        return {
          style: { backgroundColor: color },
          children: v,
        }
      }
    },
    // {
    //   title: '时间',
    //   dataIndex: 'pub_date',
    //   key: 'pub_date',
    //   render: (t) => moment.utc(t).format('MM-DD HH:mm'),
    // },
    // {
    //   title: '开盘',
    //   dataIndex: 'opening_price',
    //   key: 'opening_price',
    // },
    // {
    //   title: '收盘',
    //   dataIndex: 'closing_price',
    //   key: 'closing_price',
    // },
    // {
    //   title: '最高',
    //   dataIndex: 'top_price',
    //   key: 'top_price',
    //   className: 'mobile-column',
    // },
    // {
    //   title: '最低',
    //   dataIndex: 'low_price',
    //   key: 'low_price',
    //   className: 'mobile-column',
    // },
    // {
    //   title: '最新',
    //   dataIndex: 'latest_price',
    //   key: 'latest_price',
    //   className: 'mobile-column',
    // },
    // {
    //   title: '成交量',
    //   dataIndex: 'volume',
    //   key: 'volume',
    //   className: 'mobile-column',
    // },
    // {
    //   title: '成交额',
    //   dataIndex: 'amount',
    //   key: 'amount',
    //   className: 'mobile-column',
    // },
  ];
  const formatNum = (s?: string) => {
    if (!s) {
      return
    }
    return parseFloat(s).toFixed(2)
  }

  return (
    <div>
      <Table columns={columns} dataSource={stocksdata} pagination={pagination}
        className='mytable'
        scroll={pagination === false ? { y: 300, x: 'max-content' } : { x: 'max-content' }}
        onRow={(record) => {
          return {
            onClick: (event) => {
              setdetaildata(record)
              showModal()
              console.log(record)
            }, // 点击行
            onDoubleClick: (event) => { },
            onContextMenu: (event) => { },
            onMouseEnter: (event) => { }, // 鼠标移入行
            onMouseLeave: (event) => { },
          };
        }}
        rowKey={record => record.pub_date}
      >
      </Table>
      <Modal title="详情" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
        <p><span>日期：</span> {moment.utc(detaildata?.pub_date).format('YY-MM-DD HH:mm')}</p>
        <p><span>期数：</span> {detaildata?.time_num}</p>

        <div className='txt-color'>
          {/* <p><span>开奖结果(开盘+收盘+最高+最低)：</span> {formatNum(detaildata?.bingo_result)}</p> */}
          <p><span>开奖结果(收盘)：</span> {formatNum(detaildata?.bingo_result)}</p>
          <p><span>开奖号码：</span> {detaildata?.bingo_num.toFixed().padStart(2, '0')}</p>
        </div>
        <p><span>大小围：</span> {detaildata?.bigsmall}</p>
        <p><span>单双围：</span> {detaildata?.singledouble}</p>
        <p><span>点数：</span> {detaildata?.points}</p>
        <p><span>尾数1：</span> {detaildata?.tail_nums_1}</p>
        <p><span>尾数2：</span> {detaildata?.tail_nums_2}</p>
        <div className='txt-color'>
          <p><span>开盘：</span> {formatNum(detaildata?.opening_price)}</p>
          <p><span>收盘：</span> {formatNum(detaildata?.closing_price)}</p>
          <p><span>最高：</span> {formatNum(detaildata?.top_price)}</p>
          <p><span>最低：</span> {formatNum(detaildata?.low_price)}</p>
        </div>
        <p><span>最新：</span> {formatNum(detaildata?.latest_price)}</p>
        <p><span>成交量：</span> {detaildata?.volume}</p>
        <p><span>成交额：</span> {detaildata?.amount}</p>
      </Modal>
    </div>
  );
}

export default StocksList;
