import '../../App.css';
import { Table } from 'antd';
import { ChildReportMap, ReportByUser, ReportByUserDetail } from '../../utils/types';
import Column from 'antd/es/table/Column';
import moment from 'moment';
import { FormatMoney } from '../Utils';
import { SearchReportParams } from './SearchReportForm';
import { useState } from 'react';
import apis from '../../apis';
import ProxyReport from './ProxyReport';

function StockholderReport({ data, searchParams }: { data?: ReportByUser, searchParams?: SearchReportParams }) {
    const [childdata, setchilddata] = useState<ChildReportMap>({});
    const fetchChild = async (r: ReportByUserDetail) => {
        // 发送查询请求
        let s = 0
        let e = 0
        if(searchParams){
            s = searchParams.start
            e = searchParams.end
        }
        const res = await apis.getProxyReport(r.user_id, s, e)
        if(res.failed){
            console.log(res.error)
            return
        }
        let tmp: ChildReportMap = {...childdata}
        tmp[r.user_id] = res.data
        setchilddata(tmp)
    }
    return (
        <>
            {data &&
                <Table dataSource={data.list} rowKey={record => record.name}
                    scroll={{ x: 'max-content' }}
                    expandable={{
                        expandRowByClick: true,
                        onExpand:(expanded, r) => {
                            if(!childdata[r.user_id]){
                                fetchChild(r)
                            }
                          },
                        expandedRowRender: (r) => <ProxyReport data={childdata[r.user_id]}></ProxyReport>,
                    }}
                    summary={(pageData) => {
                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={1}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>
                                        <span>{data.total.bet_number}</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>
                                        <span>{data.total.value}</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>
                                        {FormatMoney(data.total.proxy_earn)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>
                                        <span>{data.total.points}</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>
                                        <span>{data.total.player_total_points}</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}>
                                        {FormatMoney(data.total.stockholder_rebate)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}>
                                        {FormatMoney(data.total.stockholder_zhan_win)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}>
                                        {FormatMoney(data.total.stockholder_earn)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}>
                                        {FormatMoney(data.total.higher_level_earn)}
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }}
                >
                    <Column title="代理名" dataIndex="name" key="name" />
                    <Column title="笔数" dataIndex="bet_number" key="bet_number" />
                    <Column title="下注额" dataIndex="value" key="value" />
                    <Column title="代理盈利" dataIndex="proxy_earn" key="proxy_earn" render={FormatMoney} />
                    <Column title="代理积分" dataIndex="points" key="points" />
                    <Column title="玩家总积分" dataIndex="player_total_points" key="player_total_points" />
                    <Column title="股东水" dataIndex="stockholder_rebate" key="stockholder_rebate" render={FormatMoney} />
                    <Column title="股东占输赢" dataIndex="stockholder_zhan_win" key="stockholder_zhan_win" render={FormatMoney} />
                    <Column title="股东盈利" dataIndex="stockholder_earn" key="stockholder_earn" render={FormatMoney} />
                    <Column title="上级盈利" dataIndex="higher_level_earn" key="higher_level_earn" render={FormatMoney} />
                </Table>
            }
        </>
    );
}

export default StockholderReport;
