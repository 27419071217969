import React, { useContext, useEffect, useState } from 'react';
import { App, Card } from 'antd';
import OddsSettings from './settings/OddsSettings';
import { BettingStatType, UserType } from '../utils/types';
import '../App.css'
import { UserContext } from './user/AuthContext';
import BetForm from './bet/BetForm';
import Settings from './settings/Settings';
import BetRecord from './bet/BetRecord';
import BetHistory from './bet/BetHistory';
import Stocks from './open/Stocks';
import SearchReport from './report/SearchReport';
import Bills from './Bills';
import PlayerManage from './user/PlayerManage';
import ProxyManage from './user/ProxyManage';
import StockholderManage from './user/StockholderManage';
import apis from '../apis';
import moment from 'moment';

const adminTabList = [
  { key: 'stockholderManage', label: '股东管理' },
  { key: 'openRecords', label: '开奖记录' },
  { key: 'betInfo', label: '投注记录' },
  { key: 'reports', label: '投注报表' },
  { key: 'settings', label: '设置' },
];
const adminShareTabList = [
  { key: 'openRecords', label: '开奖记录' },
  { key: 'betInfo', label: '投注记录' },
  { key: 'reports', label: '投注报表' },
];
const stockholderTabList = [
  { key: 'openRecords', label: '开奖记录' },
  { key: 'betInfo', label: '投注记录' },
  { key: 'proxyManage', label: '代理管理' },
  { key: 'reports', label: '投注报表' },
  { key: 'bills', label: '账单' },
];
const stockholderSubAccountTabList = [
  { key: 'openRecords', label: '开奖记录' },
  { key: 'betInfo', label: '投注记录' },
  { key: 'reports', label: '投注报表' },
  { key: 'bills', label: '账单' },
];
const agentTabList = [
  { key: 'openRecords', label: '开奖记录' },
  { key: 'betInfo', label: '投注记录' },
  { key: 'playerManage', label: '会员管理' },
  { key: 'reports', label: '投注报表' },
  { key: 'bills', label: '账单' },
];
const playerTabList = [
  { key: 'bet', label: '投注' },
  { key: 'openRecords', label: '开奖记录' },
  { key: 'playerBetHistory', label: '投注明细' },
  { key: 'bills', label: '账单' },
];

const Main: React.FC = () => {
  const context = useContext(UserContext);
  let tabList = playerTabList
  if (context.user.type === UserType.admin) {
    tabList = adminTabList
  }
  if (context.user.type === UserType.stockholder) {
    if(context.user.is_sub_account){
      tabList = stockholderSubAccountTabList
    }else {
      tabList = stockholderTabList
    }
  }
  if (context.user.type === UserType.agent) {
    tabList = agentTabList
  }
  if (context.user.type === UserType.adminShare) {
    tabList = adminShareTabList
  }
  const [activeTabKey, setActiveTabKey] = useState<string>(tabList[0].key);

  const onTabChange = async (key: string) => {
    setActiveTabKey(key);
    if (key === "oddsSettings") {
      // const res = await apis.getSettings()
      // if(res.failed){
      //   return
      // }
      // let str = res.data.odds
      // // const settings = parseOdds(str)
      // setOddsSettings(settings)
    }
  };

  const contentList: Record<string, React.ReactNode> = {
    playerManage: <PlayerManage></PlayerManage>,
    proxyManage: <ProxyManage></ProxyManage>,
    stockholderManage: <StockholderManage></StockholderManage>,
    shareDetail: <p>分成</p>,
    bet: <BetForm></BetForm>,
    playerBetHistory: <BetHistory />,
    settings: <Settings></Settings>,
    betInfo: <BetRecord />,
    reports: <SearchReport />,
    openRecords: <Stocks></Stocks>,
    bills: <Bills></Bills>,
  };
  const { notification } = App.useApp();
  useEffect(() => {
    async function updateCurrentBet() {
      const res = await apis.getCurrentbet()
      if (res.failed) {
        console.error("获取当前投注期数失败")
        return
      }
      context.setBettingStat(res.data)
    }
    updateCurrentBet()
    const intervalId = setInterval(() => {
      updateCurrentBet()
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [])
  useEffect(() => {
    // 状态变化时，通知
    if(context){
      let timeNum = moment.utc(context.bettingStat.dest).format('HHmm')
      if (context.bettingStat.stat === BettingStatType.betting) {
        // 开始投注
        notification.info({ message: timeNum + " 期开始投注" })
      } else if (context.bettingStat.stat === BettingStatType.opened) {
        notification.info({ message: timeNum + " 期已开奖" })
        setTimeout(()=>{
          context.updateUser(context.user.is_sub_account)
        }, 2000);
      }
    }
  }, [context?.bettingStat?.stat])

  return (
    <>
      <Card
        className='user-card'
        style={{ width: '100%', minHeight: '600px' }}
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        tabProps={{
          size: 'middle',
        }}
      >
        {contentList[activeTabKey]}
      </Card>
    </>
  );
};

export default Main;