import { useEffect, useState } from "react";
import { App, Button, Switch } from "antd";
import apis from "../../apis";
import '../../App.css';
import AdminsharePwdEditModal from "../user/AdminshareEditModal";


function OtherSettings() {
    const [isopen, setisopen] = useState(false)
    const { message } = App.useApp();

    const onClick = async (checked: boolean) => {
        const open = checked
        const res = await apis.setIsOpen(open)
        if (res.failed) {
            message.error("设置是否开市失败")
            return
        }
        setisopen(open)
    };

    useEffect(() => {
        async function getCurrentBet() {
            const res = await apis.getCurrentbet()
            if (res.failed) {
                message.error("获取当前投注期数失败")
                return
            }
            setisopen(res.data.is_open)
        }
        getCurrentBet()
    }, [])

    // debug only
    const reset = async () => {
        await apis.resetdata()
    }
    const cleanBettingData = async () => {
        await apis.cleanBettingData()
    }
    return (
        <>
            <p>是否开市：<Switch checked={isopen} onClick={onClick} /></p>
            <p> <Button type="text" className='linkbtn' onClick={reset}>重置数据</Button></p>
            <p> <Button type="text" className='linkbtn' onClick={cleanBettingData}>清空投注数据</Button></p>
            <p><AdminsharePwdEditModal></AdminsharePwdEditModal></p>
        </>
    )
}

export default OtherSettings