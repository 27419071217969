import { Col, Divider, Row } from "antd";
import { ValOfBetType } from "../utils/types";


export default function ValueOfTypes({data}:{data:ValOfBetType}){
    if(!data){
        return <></>
    }

    return (
        <>
        <Row gutter={12}>
        <Col span={8} >
            <Divider >单双围</Divider>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>单:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.single || "0"}</span></Col>
            </Row>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>双:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.double || "0"}</span></Col>
            </Row>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>大:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.big || "0"}</span></Col>
            </Row>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>小:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.small || "0"}</span></Col>
            </Row>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>围:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.wei || "0"}</span></Col>
            </Row>
        </Col>
        <Col span={16} >
            <Divider >平特尾</Divider>
            <Row gutter={12}>
                <Col span={12}>
                    <Row>
                        <Col span={10} style={{textAlign:'right'}}><span>0尾:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.tail0 || "0"}</span></Col>
                    </Row>
                    <Row>
                        <Col span={10} style={{textAlign:'right'}}><span>1尾:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.tail1 || "0"}</span></Col>
                    </Row>
                    <Row>
                        <Col span={10} style={{textAlign:'right'}}><span>2尾:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.tail2 || "0"}</span></Col>
                    </Row>
                    <Row>
                        <Col span={10} style={{textAlign:'right'}}><span>3尾:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.tail3 || "0"}</span></Col>
                    </Row>
                    <Row>
                        <Col span={10} style={{textAlign:'right'}}><span>4尾:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.tail4 || "0"}</span></Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={10} style={{textAlign:'right'}}><span>5尾:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.tail5 || "0"}</span></Col>
                    </Row>
                    <Row>
                        <Col span={10} style={{textAlign:'right'}}><span>6尾:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.tail6 || "0"}</span></Col>
                    </Row>
                    <Row>
                        <Col span={10} style={{textAlign:'right'}}><span>7尾:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.tail7 || "0"}</span></Col>
                    </Row>
                    <Row>
                        <Col span={10} style={{textAlign:'right'}}><span>8尾:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.tail8 || "0"}</span></Col>
                    </Row>
                    <Row>
                        <Col span={10} style={{textAlign:'right'}}><span>9尾:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.tail9 || "0"}</span></Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    </Row>
    <Divider style={{ marginTop: 0 }}>点数</Divider>
    <Row gutter={12}>
        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>0点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point0 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>1点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point1 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>2点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point2 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>3点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point3 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>4点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point4 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>5点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point5 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>6点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point6 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>7点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point7 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>8点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point8 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>9点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point9 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>10点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point10 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>11点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point11 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>12点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point12 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>13点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point13 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>14点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point14 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>15点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point15 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>16点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point16 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>17点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point17 || "0"}</span></Col>
            </Row>
        </Col>

        <Col span={8}>
            <Row>
                <Col span={10} style={{textAlign:'right'}}><span>18点:</span></Col> <Col span={14} style={{textAlign:'left',paddingLeft:'10px'}}><span>{data.point18 || "0"}</span></Col>
            </Row>
        </Col>

    </Row>
    </>
    )
}

