import { useState } from 'react';
import { Button, Form, Input, InputNumber, FormProps, App, Radio, RadioChangeEvent } from 'antd';
import apis from '../../apis';
import { PointsAddsub, UserInfo, UserType } from '../../utils/types';
import '../../App.css';


function UserPointsEditModal({data, onOk}:{data: UserInfo, onOk: ()=>any}) {
    const { message, modal } = App.useApp();
    const pointsOptions = [
        { label: '增加', value: 'add' },
        { label: '减少', value: 'reduce' },
    ];
    const [addsubType, setAddsubType] = useState<string>();

    const addsubTypeOnChange = ({ target: { value } }: RadioChangeEvent) => {
        setAddsubType(value);
    };
    const [addsubForm] = Form.useForm();

    const submit = async () => {
        const values = await addsubForm.validateFields();
        values.user_id = data.ID
        const res = await apis.addsubPoints(values)
        if (res.failed) {
            message.error("加减分数失败:" + res.error)
            return
        }
        onOk()
    };

    const content =
        <Form
            form={addsubForm}
            // name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            autoComplete="off"
        >
            <Form.Item<PointsAddsub>
                label="加减类型"
                name="type"
                initialValue="add"
            >
                <Radio.Group
                    options={pointsOptions}
                    onChange={addsubTypeOnChange}
                    value={addsubType}
                    optionType="button"
                    buttonStyle="solid"
                />
            </Form.Item>

            <Form.Item<PointsAddsub>
                label="分数值"
                name="value"
                initialValue={1}
                rules={[{ required: true, message: '请输入分数' }]}
            >
                <InputNumber min={1} style={{ width: '100%' }} />
            </Form.Item>
        </Form>

    const showModal = () => {
        modal.info({
            okText: "提交",
            onOk: submit,
            title: "分数加减（当前：" + data.points + "）",
            closable: true,
            maskClosable: true,
            content: content,
        })
    }

    return (
         <Button type="text" className='linkbtn' onClick={showModal}>加减</Button>
    )
}

export default UserPointsEditModal;