import { useState, useEffect, useContext } from 'react';
import '../../App.css';
import { Form, message } from 'antd';
import debounce from 'lodash/debounce';
import apis, { Result } from '../../apis';
import { UserContext } from '../user/AuthContext';
import { ReportAdminshare, ReportByUser, UserType } from '../../utils/types';
import ProxyReport from './ProxyReport';
import StockholderReport from './StockholderReport';
import { GetSearchParams, SearchReportForm, SearchReportParams } from './SearchReportForm';
import AdminReport from './AdminReport';
import AdminShareReport from './AdminShareReport';
import { AdminShareSearchReportForm } from './AdminShareSearchReportForm';

function SearchReport() {
  const [tabledata, settabledata] = useState<ReportByUser|ReportAdminshare>();
  const [searchparams, setsearchparams] = useState<SearchReportParams>();
  const context = useContext(UserContext)
  const [form] = Form.useForm();
  const doSearch = async () => {
    settabledata(undefined)
    let p = await GetSearchParams(form)
    setsearchparams(p)
    let id = context.user.ID;
    let res: Result = {} as Result;
    if(context.user.type === UserType.admin){
      res = await apis.getAdminReport( p.start, p.end)
    }
    if(context.user.type === UserType.adminShare){
      res = await apis.getAdminShareReport( p.start, p.end)
    }
    if(context.user.type === UserType.stockholder){
      res = await apis.getStockholderReport(id, p.start, p.end)
    }
    if(context.user.type === UserType.agent){
      res = await apis.getProxyReport(id, p.start, p.end)
    }
    if(res.failed){
      message.error(res.error)
      return
    }
    settabledata(res.data)
  };
  const handleSearch = debounce(doSearch, 500)

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <>
    {context.user.type === UserType.adminShare ?
      <AdminShareSearchReportForm formdata={form} search={handleSearch}></AdminShareSearchReportForm> :
      <SearchReportForm formdata={form} search={handleSearch}></SearchReportForm>
    }
    
    {context.user.type === UserType.admin &&
      <AdminReport data={tabledata as ReportByUser} searchParams={searchparams}></AdminReport>
    }
    {context.user.type === UserType.adminShare &&
      <AdminShareReport data={tabledata as ReportAdminshare} ></AdminShareReport>
    }
    {context.user.type === UserType.stockholder &&
      <StockholderReport data={tabledata as ReportByUser} searchParams={searchparams}></StockholderReport>
    }
    {context.user.type === UserType.agent &&
      <ProxyReport data={tabledata as ReportByUser} ></ProxyReport>
    }
    </>
  );
}

export default SearchReport;
