import moment from "moment";
import { UserInfo, UserType, UsertypeStr } from "../../utils/types";
import { useContext } from "react";
import { UserContext } from "./AuthContext";

function Profile({ user }: { user: UserInfo }) {
    const context = useContext(UserContext)

    return (
        <>
            <p>创建时间：<span>{moment.utc(user.CreatedAt).format('YYYY-MM-DD HH:mm:ss')}</span></p>
            <p>用户名：<span>{user.name}</span></p>
            <p>电话：<span>{user.phone}</span></p>
            <p>类型：<span>{UsertypeStr(user.type)}</span></p>
            <p>积分：<span>{user.points}</span></p>
            <p>股份：<span>{user.share}%</span></p>
            <p>返水：<span>{user.rebate}%</span></p>
            {context.user.ID !== user.ID &&
                <p>占成：<span>{user.parent_zhan_cheng}%</span></p>
            }
            {context.user.type === UserType.stockholder && 
            <>
                <p>下级数：<span>{user.proxy_num}</span></p>
                <p>下级总积分：<span>{user.proxy_points}</span></p>
            </>
            }
            {context.user.type === UserType.agent && 
            <>
                <p>下级数：<span>{user.player_num}</span></p>
                <p>下级总积分：<span>{user.player_points}</span></p>
            </>
            }
        </>
    )

}

export default Profile