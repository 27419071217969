import React, {useContext } from 'react';
import { Layout, Row, Col } from 'antd';
import Login from './user/Login'
import { UserContext } from './user/AuthContext';
import User from './Main';

const { Header, Content } = Layout;

const MyLayout: React.FC = () => {
  const context = useContext(UserContext);
  return (
    <Layout>
      <Header 
        style={{
          padding: '10px',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          // display: 'flex',
          alignItems: 'center',
          height: '70px',
          lineHeight: '20px',
          textAlign: 'left',
        }}>
        <Login />
      </Header>
      <Content >
        <Row gutter={16} >
          <Col
              xs={{ flex: '100%' }}
              sm={{ flex: '60%' }}
              md={{ flex: '50%' }}
              lg={{ flex: '40%' }}
              xl={{ flex: '30%' }}>
            {context.user.name&&<User></User>}
          </Col>
        </Row>
      </Content>
      {/* <Footer style={{ textAlign: 'center' }}>
        Copy right ©{new Date().getFullYear()}
      </Footer> */}
    </Layout>
  );
};

export default MyLayout;