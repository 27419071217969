import {useState,useEffect, useContext} from 'react';
import { Avatar, Popover, Form, Modal, App } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import apis from '../../apis';
import { jwtDecode } from "jwt-decode";
import { Button, type FormProps, Input } from 'antd';
import events from '../../utils/events';
import { UserContext } from './AuthContext';
import { UserInfo, UserType, UsertypeStr } from '../../utils/types';
import { debounce } from 'lodash';
import Profile from './Profile';
import '../../App.css'
import UserPwdEditModal from './UserPwdEditModal';
import UserSubPwdEditModal from './UserSubPwdEditModal';


const jwtKey = "jwt"
type FieldType = {
  name?: string;
  password?: string;
};
  
function Login() {
    const { message, modal } = App.useApp();
    const context = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        setIsModalOpen(false);
    };

    // 我的资料
    const showMyProfile = async () => {
        context.updateUser(context.user.is_sub_account, context.user.ID)
        modal.info({
            title:"我的资料",
            closable: true,
            maskClosable: true,
            content:<Profile user={context.user}></Profile>,
        })
    }

    const content = (
        <div>
        {!context.user.is_sub_account &&
            <p><UserPwdEditModal></UserPwdEditModal></p>
        }
        {context.user.type === UserType.stockholder &&
            <p><UserSubPwdEditModal></UserSubPwdEditModal></p>
        }
        <p> <Button type="text" className='linkbtn' onClick={showMyProfile} >我的资料</Button></p>
        <p> <Button type="text" className='linkbtnwarn' onClick={clickLogout} style={{color:"orange"}}>退出登陆</Button></p>
        </div>
    );

    function clickLogout(){
        context.setUser({} as UserInfo)
        localStorage.removeItem(jwtKey);
    }

    const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
        const res = await apis.login(values)
        if(res.failed){
            message.error("登陆失败:" + res.error)
            return
        }
        if(res.data && res.data.token){
            localStorage.setItem(jwtKey, res.data.token);
            hideModal();
            const decoded = jwtDecode<UserInfo>(res.data.token)
            console.log(decoded)
            context.updateUser(decoded.is_sub_account, decoded.ID)
        }
    };

    const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        let jwtstr = localStorage.getItem(jwtKey);
        if(jwtstr){
            const jwtdata = jwtDecode(jwtstr) as UserInfo
            context.updateUser(jwtdata.is_sub_account, jwtdata.ID)
        }

        // 事件处理函数
        const handleLogin = debounce(() => {
            clickLogout();
            showModal();
        }, 100)

        // const intervalId = setInterval(() => {
        //     context.isopen && context.updateUser()
        // }, 5000);
        // 监听事件
        events.AddReloginListener(handleLogin);
        // 清除事件监听器
        return () => {
            // clearInterval(intervalId);
            events.RemoveReloginListener(handleLogin);
        };
    }, []);
    
    if(context.user.name){
        return (
            <>
                <Popover content={content} trigger="click">
                    <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                    <span style={{ marginLeft: "10px", color: "white"}}>{context.user.is_sub_account?"@"+context.user.name:context.user.name}</span>
                    <span style={{ color: "#aaa"}}>({UsertypeStr(context.user.type)})</span>
                    <span style={{ marginLeft: "10px", color: "white"}}>总积分：{context.user.total_points}</span>
                </Popover>
                {context.user.type !== UserType.adminShare && 
                <div onClick={ ()=> context.updateUser(context.user.is_sub_account) }>
                    {context.user.type !== UserType.admin && context.user.type !== UserType.adminShare &&
                        <span style={{ marginLeft: "10px", color: "white"}}>积分：{context.user.points}</span>
                    }
                    {context.user.type === UserType.stockholder && 
                        <span style={{ marginLeft: "10px", color: "white"}}>代理总：{context.user.proxy_points}</span>
                    }
                    {context.user.type === UserType.agent && 
                        <span style={{ marginLeft: "10px", color: "white"}}>会员总：{context.user.player_points}</span>
                    }
                </div>
                }
            </>
        );
    }
    return (
        <>
        <Button type="text" className='linkbtn' onClick={showModal}>请登陆</Button>
        <Modal title="登陆" footer={null} open={isModalOpen} onOk={hideModal} onCancel={hideModal}>
            <Form
                // name="basic"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item<FieldType>
                label="用户名"
                name="name"
                rules={[{ required: true, message: '请输入用户名!' }]}
                >
                <Input />
                </Form.Item>

                <Form.Item<FieldType>
                label="密码"
                name="password"
                rules={[{ required: true, message: '请输入密码!' }]}
                >
                <Input.Password />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 22 }} style={{textAlign: "right"}}>
                <Button type="primary" htmlType="submit">
                    提交
                </Button>
                </Form.Item>
            </Form>
        </Modal>
        </>
    );
}

  
export default Login;