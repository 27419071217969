import { useState, useEffect } from 'react';
import '../../App.css';
import { Form, Button, Flex, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import apis from '../../apis';
import StocksList from './StocksList';

function Stocks() {
  const [stocksdata, setStocksdata] = useState([]);
  const [form] = Form.useForm();
  const doSearch = async () => {
    // 获取表单的值
    const values = await form.validateFields();
    let start = values.start_time;
    let end = values.end_time;

    console.log("start:", start)
    console.log("end:", end)
    // 将 dayjs 对象转换为字符串
    let startTimestamp = 0;
    let endTimestamp = 0;
    if(start){
      startTimestamp = start.unix() + 8*3600
    }
    if(end){
      endTimestamp = end.unix() + 8*3600
    }

    // 发送查询请求
    const res = await apis.getStocks(startTimestamp, endTimestamp, 0)
    if(res.failed){
      console.log(res.error)
      return
    }
    setStocksdata(res.data)
  };
  const handleSearch = debounce(doSearch, 500)

  useEffect(() => {
    const fetchData = async () => {
      const res = await apis.getStocks(0, 0, 0)
      if(res.failed){
        console.log(res.error)
        return
      }
      setStocksdata(res.data)
    };
    fetchData();
  }, []);

  return (
    <Flex gap="middle" vertical>
      <Flex wrap="wrap" gap="" align='center'>
        <Form form={form}
          layout="inline"
          initialValues={{
            start_time: null,
            end_time: null,
          }}
        >
          <Form.Item
            name="start_time"
            label="开始时间"
            rules={[{ required: false, message: '请选择开始时间' }]}
          >
            <DatePicker showTime needConfirm={false} format="YYYY-MM-DD HH:mm" placeholder="开始时间" />
          </Form.Item>
          <Form.Item
            name="end_time"
            label="结束时间"
            rules={[{ required: false, message: '请选择结束时间' }]}
          >
            <DatePicker showTime needConfirm={false} format="YYYY-MM-DD HH:mm" placeholder="结束时间" />
          </Form.Item>
        </Form>
      </Flex>
      <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch}>查询</Button>
      <StocksList stocksdata={stocksdata}></StocksList>
    </Flex>
  );
}

export default Stocks;
