import { App, Button, Card, Col, Collapse, CollapseProps, Form, FormProps, Input, InputNumber, Modal, Row, Switch } from "antd";
import apis from "../../apis";
import { useContext, useEffect, useState } from "react";
import { ValOfBetType, BetType, BetTypeName, GetBetTypeName, BettingStatType } from "../../utils/types";
import moment from "moment";
import { UserContext } from "../user/AuthContext";

import '../../App.css'
import StocksAutoRefresh from "../open/StocksAutoRefresh";
import BetTest from "./BetTest";
import BetTest2 from "./BetTest2";
import ValueOfTypes from "../ValueOfTypes";

type BetFormItem = {
    pub_date: string,
    type: BetType;
    value: string;
}

const BetForm: React.FC = () => {
    const context = useContext(UserContext)
    const [odds, setOdds] = useState<ValOfBetType>({} as ValOfBetType);
    const [betlimit, setbetlimit] = useState<ValOfBetType>({} as ValOfBetType);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [betFormVals, setBetFormVals] = useState<BetFormItem>({} as BetFormItem);
    const { message, modal } = App.useApp();

    const timeNumFormat = (t: string) => moment.utc(t).format('HHmm');
    const dateStrFormat = (t: string) => moment.utc(t).format('YYYY-MM-DD');
    const fullTimeNum = (t: string) => dateStrFormat(t) + " " + timeNumFormat(t);

    const handleOk = async () => {
        const res = await apis.bet(betFormVals)
        if (res.failed) {
            message.error("投注失败:" + res.error)
            return
        }
        message.success("投注成功")
        setIsModalOpen(false);
        context.updateUser(context.user.is_sub_account)
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    // const [oddsForm] = Form.useForm();
    const onFinish: FormProps<BetFormItem>["onFinish"] = async (values) => {
        values.pub_date = context.bettingStat.dest;
        setBetFormVals(values);
        setIsModalOpen(true);
    };

    const onFinishFailed: FormProps<BetFormItem>["onFinishFailed"] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        const initData = async () => {
            const res = await apis.getSettings()
            if (res.failed) {
                console.log(res.error)
                return
            }
            const s = res.data
            setTimeout(() => { setOdds(s.odds); setbetlimit(s.bet_limit) }, 200)
        };
        initData();
    }, [])

    const buildList = (ks: (keyof ValOfBetType)[]) => {
        const listItems = [] as JSX.Element[]
        ks.forEach((k) => {
            if (!odds[k]) {
                return
            }
            let label = <>
                <span className="bettype">{BetTypeName[k]}</span>
                <span style={{ color: "orange" }}> [{odds[k]}倍] 限 {betlimit[k]}</span>
            </>
            listItems.push(
                <Form
                    key={k}
                    // form={oddsForm}
                    labelCol={{ span: 14, sm: { span: 14 }, xs: { span: 14 } }}
                    wrapperCol={{ span: 14, sm: { span: 14 }, xs: { span: 10 } }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="type"
                        hidden={true}
                        initialValue={k}
                    >
                        <Input />
                    </Form.Item>
                    <Row gutter={12}>
                        <Col span={19}>
                            <Form.Item name="value" label={label} initialValue={10} >
                                <InputNumber max={betlimit[k]}></InputNumber>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: "right" }}>
                                <Button type="primary" htmlType="submit">
                                    投注
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )

        })
        return listItems
    }

    const part1 = buildList(['single', 'double', 'big', 'small', 'wei'])
    const part2 = buildList(['tail0', 'tail1', 'tail2', 'tail3', 'tail4', 'tail5', 'tail6', 'tail7', 'tail8', 'tail9'])
    const part3 = buildList(['point0', 'point1', 'point2', 'point3', 'point4', 'point5', 'point6', 'point7', 'point8', 'point9', 'point10', 'point11', 'point12', 'point13', 'point14', 'point15', 'point16', 'point17', 'point18'])
    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: '单双大小围',
            children: part1,
        },
        {
            key: '2',
            label: '平特尾',
            children: part2,
        },
        {
            key: '3',
            label: '点数',
            children: part3,
        },
    ];
    let formList = (
        <>
            <Collapse items={items} ghost defaultActiveKey={['1']} className="betform" />
        </>
    )
    const [showtest, setshowtest] = useState(false)
    const switchTest = (checked: boolean) => {
        setshowtest(checked)
    };
    const [bettingstat, setbettingstat] = useState(context.bettingStat.stat)

    useEffect(() => {
        setbettingstat(context.bettingStat.stat)
    }, [context.bettingStat?.stat])


    const [randnum, setrandnum] = useState("00");

    useEffect(() => {
        const interval = setInterval(() => {
            let r = Math.floor(Math.random() * 100)
            setrandnum(r.toFixed().padStart(2, '0'));
        }, 200);

        return () => clearInterval(interval);
    }, []);
    return (
        <Card>
            <StocksAutoRefresh></StocksAutoRefresh>
            {/* <p>投注测试：<Switch checked={showtest} onClick={switchTest} /></p>
            {showtest&&
            <>
                <BetTest2></BetTest2>
                <BetTest></BetTest>
            </>
            } */}
            <Modal title="投注确认" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div style={{ textAlign: "center" }}>
                    <p>期数：<span style={{ color: "red" }}>{fullTimeNum(context.bettingStat.dest)}</span></p>
                    <p>类型：<span style={{ color: "red" }}>{GetBetTypeName(betFormVals.type)}</span></p>
                    <p>金额：<span style={{ color: "red" }}>{betFormVals.value}</span></p>
                </div>
            </Modal>
            <Modal
                title={fullTimeNum(context.bettingStat.dest) + " 期"}
                open={bettingstat === BettingStatType.openning || bettingstat === BettingStatType.opened}
                closable={false}
                footer={null}
                >
                <p style={{ fontSize: 26, color: "red" }}>

                    {
                        context.bettingStat.stat === BettingStatType.opened && context.bettingStat.stock &&
                        <strong>开奖号码：{context.bettingStat.stock.bingo_num.toFixed().padStart(2, '0')}</strong>
                    }
                    {
                        context.bettingStat.stat === BettingStatType.openning &&
                        <strong><span className="scale">开奖中：</span><span>{randnum}</span></strong>
                    }
                </p>
                <strong style={{ fontSize: 18 }}>最近未中奖期数：</strong>
                <ValueOfTypes data={context.bettingStat?.history}></ValueOfTypes>
            </Modal>
            <div style={{fontSize: '20px', fontWeight:"bold"}}>
                {context.bettingStat.stat === BettingStatType.betting &&
                    <>
                        <p style={{ width: '100%', textAlign: "left" }}>
                            <span style={{ paddingRight: '10px' }}>投注期数：{timeNumFormat(context.bettingStat.dest)}</span>
                            <span style={{ display: "inline-block", width: "160px", paddingLeft: "30px" }}>
                                <span style={context.bettingStat.sec_left < 10 ? { color: 'red' } : {}}>倒计时：{context.bettingStat.sec_left}</span>
                            </span>
                        </p>
                        {formList}
                    </>
                }
                {context.bettingStat.stat === BettingStatType.openning &&
                    <>
                        <p>当前：<span style={{ color: 'red' }}>等待开奖中。。。</span></p>
                    </>
                }
                {context.bettingStat.stat === BettingStatType.closed &&
                    <p>当前：<span style={{ color: 'red' }}>未开市</span></p>
                }
                <p><a target="_blank" href="https://waptest.eastmoney.com/quote/stockmore_new.html?id=000001&mk=1">实时官方数据</a></p>
            </div>
        </Card>
    );
};


export default BetForm;