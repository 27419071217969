import { useState } from 'react';
import '../../App.css';
import { Form, Button, Flex, DatePicker, Radio, RadioChangeEvent, FormInstance } from 'antd';
import { SearchOutlined } from '@ant-design/icons';


export type SearchReportParams = {
  start: number;
  end: number;
}
export async function GetSearchParams(form: FormInstance<any>) {
  // 获取表单的值
  const values = await form.validateFields();
  let start = values.start_time;
  let end = values.end_time;
  let type = values.type
  let day = values.day
  let week = values.week

  if (type === 'day' && day) {
    start = day.clone().startOf('day');
    end = day.clone().endOf('day');
  } else if (type === 'week' && week) {
    start = week.clone().startOf('week');
    end = week.clone().endOf('week');
  }
  console.log('Start: ', start);
  console.log('End: ', end);

  let startTimestamp = 0;
  let endTimestamp = 0;
  if (start) {
    startTimestamp = start.unix()
  }
  if (end) {
    endTimestamp = end.unix()
  }

  let res: SearchReportParams = {
    start: startTimestamp,
    end: endTimestamp,
  }
  return res
};
export function SearchReportForm({ formdata, search }: { formdata: FormInstance<any>, search: React.MouseEventHandler<HTMLElement> }) {
  const [reqtype, setReqtype] = useState<string>('day');

  const handleTypeChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setReqtype(e.target.value);
  };
  // const handleSearch = debounce(doSearch, 500)

  return (
    <>
      <Flex gap="middle" vertical>
        <Flex wrap="wrap" gap="" align='center'>
          <Form form={formdata}
            layout="inline"
            initialValues={{
              start_time: null,
            }}
          >
            <Form.Item
              name="type"
              label="查询类型："
              initialValue={'day'}
              rules={[{ required: false, message: '请选择时间' }]}
            >
              <Radio.Group onChange={handleTypeChange} >
                <Radio value={'day'}>日</Radio>
                <Radio value={'week'}>周</Radio>
                <Radio value={'time'}>期</Radio>
              </Radio.Group>
            </Form.Item>
            {reqtype === 'day' &&
              <Form.Item
                name="day"
                label="日"
                rules={[{ required: false, message: '请选择日期' }]}
              >
                <DatePicker />
              </Form.Item>
            }
            {reqtype === 'week' &&
              <Form.Item
                name="week"
                label="周"
                rules={[{ required: false, message: '请选择周' }]}
              >
                <DatePicker picker="week" />
              </Form.Item>
            }
            {reqtype === 'time' &&
              <>
                <Form.Item
                  name="start_time"
                  label="开始时间"
                  rules={[{ required: false, message: '请选择时间' }]}
                >
                  <DatePicker showTime needConfirm={false} format="YYYY-MM-DD HH:mm" placeholder="时间" />
                </Form.Item>
                <Form.Item
                  name="end_time"
                  label="结束时间"
                  rules={[{ required: false, message: '请选择结束时间' }]}
                >
                  <DatePicker showTime needConfirm={false} format="YYYY-MM-DD HH:mm" placeholder="结束时间" />
                </Form.Item>
              </>
            }

          </Form>
        </Flex>
        <Button type="primary" icon={<SearchOutlined />} onClick={search}>查询</Button>
      </Flex>
    </>
  );
}

