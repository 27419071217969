import { useContext } from 'react';
import { Button, Form, Input, App } from 'antd';
import apis from '../../apis';
import { UserContext } from './AuthContext';
import '../../App.css';

function UserSubPwdEditModal() {
    const { message, modal } = App.useApp();
    const context = useContext(UserContext);
    const [usereditForm] = Form.useForm();

    const submit = async () => {
        const values = await usereditForm.validateFields();
        values.id = context.user.ID;
        const res = await apis.changeSubpwd(values)
        if (res.failed) {
            message.error("修改子账号密码失败:" + res.error)
            return
        }
        // onOk()
    };

    const content =
        <Form
            form={usereditForm}
            // name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            autoComplete="off"
        >
            <Form.Item
                label="密码"
                name="new"
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                label="密码确认"
                name="new_confirm"
            >
                <Input.Password />
            </Form.Item>
        </Form>

    const showModal = () => {
        modal.info({
            okText: "提交",
            onOk: submit,
            title: "修改子账号密码",
            closable: true,
            maskClosable: true,
            content: content,
        })    
    }

    return (
         <Button type="text" className='linkbtn' onClick={showModal}>修改子账号密码</Button>
    )
}

export default UserSubPwdEditModal;