

export const FormatMoney = (v:string) =>{
    let res = <span style={{ color: "green" }}>+{v}</span>
    if(parseFloat(v) < 0){
        res = <span style={{ color: "red" }}>{v}</span>
    }
    return (
        res
    )
}