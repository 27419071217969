import { useContext, useEffect, useState } from "react";
import { UserContext } from "../user/AuthContext";
import { Form, Select } from "antd";
import apis from "../../apis";
import { UserType } from "../../utils/types";

export default function UserSelectForm() {
    const [userlist, setuserlist] = useState<any[]>([]);
    const [userlist2, setuserlist2] = useState<any[]>([]);
    const [userlist3, setuserlist3] = useState<any[]>([]);
    const context = useContext(UserContext)

    const onChangeFirstLevel = async (value: number) => {
        setuserlist2([])
        let opts = await getUserOpts(value)
        setuserlist2(opts)
    };
    const onChangeSecondLevel = async (value: number) => {
        setuserlist3([])
        let opts = await getUserOpts(value)
        setuserlist3(opts)
    };

    const getUserOpts = async (id?: number) => {
        const res = await apis.getLowerUsers(id)
        if (res.failed) {
            console.error(res.error)
            return []
        }
        let opts = [];
        for (let obj of res.data) {
            opts.push({ value: obj.ID, label: obj.name })
        }
        return opts
    }

    useEffect(() => {
        const loadFirstLevel = async () => {
            let opts = await getUserOpts()
            if (context.user.type === UserType.admin) {
                setuserlist(opts)
            } else if (context.user.type === UserType.stockholder) {
                setuserlist2(opts)
            } else if (context.user.type === UserType.agent) {
                setuserlist3(opts)
            }
        }
        loadFirstLevel();
    }, []);

    return (
        <>
            {context.user.type === UserType.admin &&
                <>
                    <Form.Item
                        name="sid"
                        label="股东"
                    >
                        <Select
                            showSearch
                            placeholder="选择股东"
                            onChange={onChangeFirstLevel}
                            options={userlist}
                        />
                    </Form.Item>
                    <Form.Item
                    name="pid"
                    label="代理"
                    >
                    <Select
                        showSearch
                        placeholder="选择代理"
                        onChange={onChangeSecondLevel}
                        options={userlist2}
                    />
                    </Form.Item>
                    <Form.Item
                        name="uid"
                        label="用户"
                    >
                        <Select
                            showSearch
                            placeholder="选择用户"
                            options={userlist3}
                        />
                    </Form.Item>
                </>
            }
            {context.user.type === UserType.stockholder &&
            <>
                <Form.Item
                    name="pid"
                    label="代理"
                >
                    <Select
                        showSearch
                        placeholder="选择代理"
                        onChange={onChangeSecondLevel}
                        options={userlist2}
                    />
                </Form.Item>
                <Form.Item
                name="uid"
                label="用户"
                >
                    <Select
                        showSearch
                        placeholder="选择用户"
                        options={userlist3}
                    />
                </Form.Item>
            </>
            }
            {context.user.type === UserType.agent &&
            <Form.Item
                name="uid"
                label="用户"
            >
                <Select
                    showSearch
                    placeholder="选择用户"
                    options={userlist3}
                />
            </Form.Item>
            }
        </>
    )
}