import '../../App.css';
import { Table } from 'antd';
import { ChildReportMap, ReportByUser, ReportByUserDetail } from '../../utils/types';
import Column from 'antd/es/table/Column';
import moment from 'moment';
import { FormatMoney } from '../Utils';
import { AdminShareSearchReportParams } from './AdminShareSearchReportForm';
import { useContext, useState } from 'react';
import { UserContext } from '../user/AuthContext';
import apis from '../../apis';
import StockholderReport from './StockholderReport';

function AdminReport({ data, searchParams }: { data?: ReportByUser, searchParams?:AdminShareSearchReportParams }) {
    const [childdata, setchilddata] = useState<ChildReportMap>({});
    const context = useContext(UserContext)
    const fetchChild = async (r: ReportByUserDetail) => {
        // 发送查询请求
        let s = 0
        let e = 0
        if(searchParams){
            s = searchParams.start
            e = searchParams.end
        }
        const res = await apis.getStockholderReport(r.user_id, s, e)
        if(res.failed){
            console.log(res.error)
            return
        }
        let tmp: ChildReportMap = {...childdata}
        tmp[r.user_id] = res.data
        setchilddata(tmp)
    }

    return (
        <>
            {data &&
                <Table dataSource={data.list} rowKey={record => record.name}
                    scroll={{ x: 'max-content' }}
                    expandable={{
                        expandRowByClick: true,
                        onExpand:(expanded, r) => {
                            if(!childdata[r.user_id]){
                                fetchChild(r)
                            }
                          },
                        expandedRowRender: (r) => <StockholderReport data={childdata[r.user_id]} searchParams={searchParams}></StockholderReport>,
                        rowExpandable: (record) => record.name !== 'Not Expandable',
                    }}
                    summary={(pageData) => {
                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={1}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>
                                        <span>{data.total.bet_number}</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>
                                        <span>{data.total.value}</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>
                                        {FormatMoney(data.total.stockholder_rebate)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>
                                        {FormatMoney(data.total.stockholder_zhan_win)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>
                                        {FormatMoney(data.total.stockholder_earn)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>
                                        <span>{data.total.points}</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}>
                                        <span>{data.total.proxy_total_points}</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}>
                                        <span>{data.total.player_total_points}</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}>
                                        {FormatMoney(data.total.banker_rebate)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}>
                                        {FormatMoney(data.total.banker_zhan_win)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}>
                                        {FormatMoney(data.total.banker_earn)}
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }}
                >
                    <Column title="股东名" dataIndex="name" key="name" />
                    <Column title="笔数" dataIndex="bet_number" key="bet_number" />
                    <Column title="下注额" dataIndex="value" key="value" />
                    <Column title="股东水" dataIndex="stockholder_rebate" key="stockholder_rebate" render={FormatMoney}/>
                    <Column title="股东占输赢" dataIndex="stockholder_zhan_win" key="stockholder_zhan_win" render={FormatMoney} />
                    <Column title="股东盈利" dataIndex="stockholder_earn" key="stockholder_earn" render={FormatMoney}/>
                    <Column title="股东积分" dataIndex="points" key="points" />
                    <Column title="代理总积分" dataIndex="proxy_total_points" key="proxy_total_points" />
                    <Column title="会员总积分" dataIndex="player_total_points" key="player_total_points" />
                    <Column title="庄反水" dataIndex="banker_rebate" key="banker_rebate" render={FormatMoney}/>
                    <Column title="庄家占输赢" dataIndex="banker_zhan_win" key="banker_zhan_win" render={FormatMoney}/>
                    <Column title="庄盈利" dataIndex="banker_earn" key="banker_earn" render={FormatMoney}/>
                </Table>
            }
        </>
    );
}

export default AdminReport;
