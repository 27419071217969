import { useState, useEffect, useContext } from 'react';
import '../../App.css';
import apis from '../../apis';
import BetSumComp from './BetSumComp';
import { UserType } from '../../utils/types';
import { UserContext } from '../user/AuthContext';

function BetSumRealtime() {
  const [tabledata, settabledata] = useState();
  const context = useContext(UserContext)
  const doSearch = async () => {
    let pid = 0;
    let sid = 0;
    if(context.user.type === UserType.stockholder){
      sid = context.user.ID
    }else if(context.user.type === UserType.agent){
      pid = context.user.ID
    }
    // 发送查询请求
    const res = await apis.getBetsumRealtime(pid, sid)
    if(res.failed){
      console.log(res.error)
      return
    }
    settabledata(res.data)
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      doSearch()
    }, 5000); // 每5秒发送一次查询请求
    doSearch();

    // 清理函数，在组件卸载时取消定时器
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      {tabledata && <BetSumComp data={tabledata}></BetSumComp>}
    </>
  );
}

export default BetSumRealtime;
