import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { jwtDecode } from "jwt-decode";
import { UserInfo, PointsAddsub } from './utils/types';
import events from './utils/events';
import { isString } from 'lodash';

// const baseurl = process.env.REACT_APP_API_URL || 'http://47.95.13.159:8007'
const baseurl = new URL(window.location.href).origin;

const myaxios = axios.create({
  baseURL: baseurl,
  timeout: 5000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
  }
});

// 如果已经登陆则填充 jwt 头部，同时检查过期
function fillJwt(headers: any, jwt: string){
  headers.Authorization = "Bearer " + jwt
  return headers
}

async function refreshJwt(jwt:string): Promise<string> {
  const decoded = jwtDecode(jwt);
  if(decoded.exp){
    const now = new Date().getTime()
    if(decoded.exp * 1000 < now){
      // refresh
      try {
        const { data, status} = await myaxios.get("/api/auth/refresh_token",{headers: fillJwt({}, jwt)})
        console.log('response status is: ', status);
        if(data && data.token){
          jwt = data.token
          localStorage.setItem("jwt", jwt)
        }
      } catch (error) {
        console.log(error)
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 401){
            events.Relogin()
          }
          console.log('error message: ', error.message);
        } else {
          console.log('unexpected error: ', error);
        }
      }
    }
  }
  return jwt
}

async function reqWithJwt(c: AxiosRequestConfig<any>){
  let jwt = localStorage.getItem("jwt") || ""
  if(!jwt){
    console.log("jwt is empty")
    events.Relogin()
    throw("jwt is empty")
  }
  jwt = await refreshJwt(jwt)
  c.headers = c.headers || {}
  fillJwt(c.headers, jwt)
  return await myaxios(c)
}

export type Result = {
  data: any,
  status: number,
  // r: AxiosResponse<any, any>;
  failed: boolean;
  error: string;
}
async function trywrapper(func: () => Promise<AxiosResponse<any, any>>, sucMsg?: boolean) {
  let res: Result = {} as Result
  try {
    const { data, status} = await func();
    res.data = data
    res.status = status
  } catch (error) {
    let msg: any
    if (axios.isAxiosError(error)) {
      msg = error.message
      res.data = error.response?.data
      let reserr = res.data?.error
      if(isString(reserr)){
        res.error = reserr
      }
      if(isString(reserr?.Message)){
        res.error = reserr.Message
      }
      if(isString(res.data?.message)){
        res.error = res.data.message
      }
      res.status = error.response?.status || 0
    } else {
      msg = error
    }
    res.failed = true
    res.error = res.error || msg
  }
  return res
}


async function getStocks(s: number, e: number, l: number) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/stocks_list",
      params: { s: s, e: e, l: l},
    })
  })
}


async function login(params: Object) {
  return trywrapper(async () => {
    return await axios.post<any>(baseurl+'/api/login', params, {
      headers: {
        Accept: 'application/json',
      },
    });
  })
}

// 获取下级用户列表
async function getLowerUsers(id?: number) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/user/lower",
      params: {id:id},
    })
  })
}
// 获取下级用户列表完整信息
async function getLowerUsersFull(id?: number) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/user/lowerinfo",
      params: {id:id},
    })
  })
}

async function getUser(id: number) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/user",
      params: {id:id},
    })
  })
}

async function addUser(user: UserInfo) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "post",
      data: user,
      url: "/api/auth/user",
    })
  })
}
async function checkUserName(user: UserInfo) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "post",
      data: user,
      url: "/api/auth/user/check",
    })
  })
}

async function updateUser(user: UserInfo) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "put",
      data: user,
      url: "/api/auth/user",
    })
  })
}

async function addsubPoints(info: PointsAddsub) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "put",
      data: info,
      url: "/api/auth/user/points",
    })
  })
}

async function delUser(id: number) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "delete",
      params: {id:id},
      url: "/api/auth/user",
    })
  })
}

async function getSettings() {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/settings",
    })
  })
}
async function updateFirstLevelSettings(data: any) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "put",
      data: data,
      url: "/api/auth/admin/settings/firstlevel",
    })
  })
}

async function updateOddsSettings(data: any) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "put",
      data:data,
      url: "/api/auth/admin/settings/odds",
    })
  })
}

async function updateBetlimitSettings(data: any) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "put",
      data:data,
      url: "/api/auth/admin/settings/betlimit",
    })
  })
}
async function updateTotalBetlimitSettings(data: any) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "put",
      data:data,
      url: "/api/auth/admin/settings/total_betlimit",
    })
  })
}

async function bet(data: any) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "post",
      data:data,
      url: "/api/auth/player/bet",
    })
  })
}

async function getCurrentbet() {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/currentbet",
    })
  })
}

async function getIsOpen() {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/isopen",
    })
  })
}

async function setIsOpen(open: boolean) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "post",
      url: "/api/auth/admin/isopen",
      params: {open:open},
    })
  })
}

async function getBetList(uid: number, pid: number, sid: number, s: number, e: number, l: number) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/betlist",
      params: { uid:uid, pid:pid, sid:sid, s: s, e: e, l: l},
    })
  })
}

async function getOpenList(s: number, e: number, l: number) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/openlist",
      params: { s: s, e: e, l: l},
    })
  })
}

async function getBetRealtime() {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/bet/realtime",
    })
  })
}

async function getBills(id: number) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/bill",
      params: { id:id},
    })
  })
}

async function getBetsum(uid:number, pid:number, sid:number, t:number) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/betsum",
      params: {uid:uid,pid:pid,sid:sid,t:t},
    })
  })
}

async function getBetsumRealtime(pid:number, sid:number) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/betsum/realtime",
      params: {pid:pid,sid:sid},
    })
  })
}

async function getProxyReport(pid:number, s:number, e:number) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/report/proxy",
      params: {pid:pid,s:s,e:e},
    })
  })
}

async function getStockholderReport(sid:number, s:number, e:number) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/report/stockholder",
      params: {sid:sid,s:s,e:e},
    })
  })
}
async function getAdminShareReport(s:number, e:number) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/report/adminshare",
      params: {s:s,e:e},
    })
  })
}
async function getAdminReport(s:number, e:number) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/report/admin",
      params: {s:s,e:e},
    })
  })
}
async function resetdata() {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/admin/reset",
    })
  })
}

async function cleanBettingData() {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "get",
      url: "/api/auth/admin/reset/clean_betting_data",
    })
  })
}

async function changePwd(data: any) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "post",
      data:data,
      url: "/api/auth/user/pwd",
    })
  })
}

async function changeSubpwd(data: any) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "post",
      data:data,
      url: "/api/auth/user/subpwd",
    })
  })
}

async function changeAdminsharePwd(data: any) {
  return trywrapper(async () => {
    return await reqWithJwt({
      method: "post",
      data:data,
      url: "/api/auth/admin/adminshare/pwd",
    })
  })
}

const apis = {
  getStocks,
  login,
  getLowerUsers,
  getLowerUsersFull,
  getUser,
  addUser,
  checkUserName,
  updateUser,
  addsubPoints,
  delUser,
  getSettings,
  updateFirstLevelSettings,
  updateOddsSettings,
  updateBetlimitSettings,
  updateTotalBetlimitSettings,
  bet,
  getCurrentbet,
  getIsOpen,
  setIsOpen,
  getBetList,
  getOpenList,
  getBetRealtime,
  getBills,
  getBetsum,
  getBetsumRealtime,
  getProxyReport,
  getStockholderReport,
  getAdminReport,
  getAdminShareReport,
  resetdata,
  cleanBettingData,
  changePwd,
  changeSubpwd,
  changeAdminsharePwd,
}
export default apis