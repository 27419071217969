import { useState, useEffect } from 'react';
import { Space, Table, App } from 'antd';
import apis from '../../apis';
import { ChildUserlistMap, UserInfo } from '../../utils/types';
import Profile from './Profile';
import Column from 'antd/es/table/Column';
import UserAddModal from './UserAddModal';
import UserEditModal from './UserEditModal';
import UserDelModal from './UserDelModal';
import UserPointsEditModal from './UserPointsEdit';
import PlayerManage from './PlayerManage';

function ProxyManage({ id, noEdit }: { id?: number, noEdit?: boolean }) {
    const [tabledata, setTabledata] = useState<UserInfo[]>([]);
    const { modal } = App.useApp();
    const showUserProfile = function (u: UserInfo) {
        modal.info({
            title: "用户资料",
            closable: true,
            maskClosable: true,
            content: <Profile user={u}></Profile>,
        })
    }

    const updateData = async () => {
        const res = await apis.getLowerUsersFull(id)
        if (res.failed) {
            console.error(res.error)
            return
        }
        setTabledata(res.data)
    };
    const [childdata, setchilddata] = useState<ChildUserlistMap>({});
    const fetchChild = async (r: UserInfo) => {
        const res = await apis.getLowerUsersFull(r.ID)
        if (res.failed) {
            console.log(res.error)
            return
        }
        let tmp: ChildUserlistMap = { ...childdata }
        tmp[r.ID] = res.data
        setchilddata(tmp)
    }

    // 初始化
    useEffect(() => {
        updateData();
    }, []);
    return (
        <div>
            {!noEdit &&
                <p style={{ margin: 0 }}><span style={{ color: "#aaa" }}>双击列表查看详情</span><span style={{ float: 'right' }}><UserAddModal onOk={updateData}></UserAddModal></span></p>
            }
            <Table dataSource={tabledata} rowKey={record => record.ID}
                expandable={{
                    expandRowByClick: true,
                    onExpand: (expanded, r) => {
                        if (!childdata[r.ID]) {
                            fetchChild(r)
                        }
                    },
                    expandedRowRender: (r) => <PlayerManage id={r.ID} noEdit={true}></PlayerManage>,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                        }, // 点击行
                        onDoubleClick: (event) => {
                            showUserProfile(record)
                        },
                    };
                }}
            >
                <Column title="名称" dataIndex="name" key="name" />
                <Column title="会员数" dataIndex="num_children" key="num_children" />
                <Column title="会员积分" dataIndex="users_points" key="users_points" />
                <Column title="反水" dataIndex="rebate" key="rebate" />
                <Column title="占成" dataIndex="parent_zhan_cheng" key="parent_zhan_cheng" />
                <Column title="分数" dataIndex="points" key="points" />
                {!noEdit &&
                    <Column
                        title="操作"
                        key="action"
                        render={(_: any, record: UserInfo) => (
                            <Space size="middle">
                                <UserPointsEditModal data={record} onOk={updateData}></UserPointsEditModal>
                                <UserEditModal data={record} onOk={updateData}></UserEditModal>
                                <UserDelModal data={record} onOk={updateData}></UserDelModal>
                            </Space>
                        )}
                    />
                }
            </Table>

        </div>)
}

export default ProxyManage;