import '../../App.css';
import { Table } from 'antd';
import { ReportByUser, ReportByUserDetail } from '../../utils/types';
import Column from 'antd/es/table/Column';
import moment from 'moment';
import { FormatMoney } from '../Utils';

function ProxyReport({ data }: { data?: ReportByUser }) {
    return (
        <>
            {data &&
                <Table dataSource={data.list} rowKey={record => record.name}
                    scroll={{ x: 'max-content' }}
                    summary={(pageData) => {
                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={1}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>
                                        <span>{data.total.bet_number}</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>
                                        <span>{data.total.value}</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>
                                        {FormatMoney(data.total.player_earn)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>
                                        {FormatMoney(data.total.proxy_earn)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>
                                        {FormatMoney(data.total.proxy_rebate)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>
                                        {FormatMoney(data.total.proxy_zhan_win)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}>
                                        {FormatMoney(data.total.higher_level_earn)}
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }}
                >
                    <Column title="会员名" dataIndex="name" key="name" />
                    <Column title="笔数" dataIndex="bet_number" key="bet_number" />
                    <Column title="下注额" dataIndex="value" key="value" />
                    <Column title="玩家盈利" dataIndex="player_earn" key="player_earn" render={FormatMoney} />
                    <Column title="代理盈利" dataIndex="proxy_earn" key="proxy_earn" render={FormatMoney} />
                    <Column title="代理水" dataIndex="proxy_rebate" key="proxy_rebate" render={FormatMoney} />
                    <Column title="代理占输赢" dataIndex="proxy_zhan_win" key="proxy_zhan_win" render={FormatMoney} />
                    <Column title="上级盈利" dataIndex="higher_level_earn" key="higher_level_earn" render={FormatMoney} />
                </Table>
            }
        </>
    );
}

export default ProxyReport;
