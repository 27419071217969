import { App, Table } from "antd";
import { BetDetail, GetBetTypeName, UserType } from "../../utils/types";
import Column from "antd/es/table/Column";
import moment from "moment";
import { ClockCircleTwoTone } from "@ant-design/icons";
import { useContext } from "react";
import { UserContext } from "../user/AuthContext";
import { FormatMoney } from "../Utils";
import "./BetCss.css"


function BetList({ data }: { data: BetDetail[] }) {
    const { modal } = App.useApp();
    const context = useContext(UserContext)
    const extraInfo = (r: BetDetail) => {
        let userRelatedInfo = (
            <>
                <p>投注后我的积分：<span>{r.bet_left_points}</span></p>
                <p>开奖后我的积分：<span>{r.user_new_points}</span></p>
            </>
        )

        if (context.user.type === UserType.agent) {
            userRelatedInfo = (
                <>
                    <p>开奖后我的积分：<span>{r.proxy_new_points}</span></p>
                    <p>水：<span>{r.proxy_rebate}</span></p>
                    <p>占：<span>{r.proxy_zhan}</span></p>
                    <p>占输赢：<span>{r.proxy_zhan_win}</span></p>
                    <p>输赢：<span>{r.proxy_earn}</span></p>
                </>
            )
        } else if (context.user.type === UserType.stockholder) {
            userRelatedInfo = (
                <>
                    <p>开奖后我的积分：<span>{r.stockholder_new_points}</span></p>
                    <p>水：<span>{r.stockholder_rebate}</span></p>
                    <p>占：<span>{r.stockholder_zhan}</span></p>
                    <p>占输赢：<span>{r.stockholder_zhan_win}</span></p>
                    <p>输赢：<span>{r.stockholder_earn}</span></p>
                </>
            )
        } else if (context.user.type === UserType.admin) {
            userRelatedInfo = (
                <>
                    <p>大盘赢：<span>{r.banker_earn}</span></p>
                </>
            )
        }
        return userRelatedInfo
    }
    const fullInfo = (r: BetDetail) => (
        <>
            <p>类型：<span>{GetBetTypeName(r.type)}</span></p>
            <p>赔率：<span>{r.odds}</span></p>
            <p>金额：<span>{r.value}</span></p>
            <p>期数：<span>{moment.utc(r.pub_date).format('MM-DD HHmm')}</span></p>
            <p>是否开奖：<span>{r.opened ? "已开奖" : "未开奖"}</span></p>
            <p>开奖号码：<span>{r.bingo_num.toFixed().padStart(2, '0')}</span></p>
            <p>玩家输赢：<span>{r.player_earn}</span></p>
            {extraInfo(r)}
        </>
    )

    const OnClick = (r: BetDetail) => {
        console.log(context.user)
        modal.info({
            title: "投注详情",
            closable: true,
            maskClosable: true,
            content: fullInfo(r),
        })
    }
    const formatOpenedVal = (v: any, r: BetDetail) => {
        if (!r.opened) {
            return <ClockCircleTwoTone />
        } else {
            return v
        }
    }
    const formatOpenedMoney = (v: any, r: BetDetail) => {
        if (!r.opened) {
            return <ClockCircleTwoTone />
        } else {
            return FormatMoney(v)
        }
    }
    return (
        <>
            <Table dataSource={data} rowKey={record => record.ID} scroll={{ x: 'max-content' }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            OnClick(record)
                        }, // 点击行
                        onDoubleClick: (event) => {
                            // showUserProfile(record)
                        },
                        onContextMenu: (event) => { },
                        onMouseEnter: (event) => { }, // 鼠标移入行
                        onMouseLeave: (event) => { },
                    };
                }}
            >
                <Column title="期数" dataIndex="pub_date" key="pub_date" className="betdate" render={(t) => moment.utc(t).format('MM-DD HHmm')} />
                <Column title="时间" dataIndex="CreatedAt" key="CreatedAt" className="betdate" render={(t) => moment.utc(t).utcOffset(8).format('HH:mm:ss')} />
                <Column title="类型" dataIndex="type" key="type" render={(t) => GetBetTypeName(t)} />
                <Column title="金额" dataIndex="value" key="value" />
                <Column title="奖号" dataIndex="bingo_num" key="bingo_num" render={(v, r:BetDetail) => formatOpenedVal(v.toFixed().padStart(2, '0'), r)} />
                {context.user.type === UserType.user &&
                    <Column title="输赢" dataIndex="player_earn" key="player_earn" render={formatOpenedMoney} />
                }

                {context.user.type === UserType.agent &&
                    <>
                        <Column title="玩家" dataIndex="user_name" key="user_name" />
                        <Column title="玩家输赢" dataIndex="player_earn" key="player_earn" render={formatOpenedMoney} />
                        <Column className="agentBetColumn" title="代理水" dataIndex="proxy_rebate" key="proxy_rebate" render={formatOpenedMoney} />
                        <Column className="agentBetColumn" title="代理占输赢" dataIndex="proxy_zhan_win" key="proxy_zhan_win" render={formatOpenedMoney} />
                        <Column className="agentBetColumn" title="代理输赢" dataIndex="proxy_earn" key="proxy_earn" render={formatOpenedMoney} />
                    </>
                }

                {context.user.type === UserType.stockholder &&
                    <>
                        <Column title="玩家" dataIndex="user_name" key="user_name" />
                        <Column title="玩家输赢" dataIndex="player_earn" key="player_earn" render={formatOpenedMoney} />
                        <Column className="agentBetColumn" title="代理名" dataIndex="proxy_name" key="proxy_name" />
                        <Column className="agentBetColumn" title="代理占%" dataIndex="proxy_zhan_percent" key="proxy_zhan_percent" />
                        <Column className="stockholderBetColumn" title="股东占%" dataIndex="stockholder_zhan_percent" key="stockholder_zhan_percent" />
                        <Column className="agentBetColumn" title="代理水" dataIndex="proxy_rebate" key="proxy_rebate" render={formatOpenedVal} />
                        <Column className="stockholderBetColumn" title="股东水" dataIndex="stockholder_rebate" key="stockholder_rebate" render={formatOpenedVal} />
                        <Column className="agentBetColumn" title="代理输赢" dataIndex="proxy_earn" key="proxy_earn" render={formatOpenedMoney} />
                        <Column className="stockholderBetColumn" title="股东占输赢" dataIndex="stockholder_zhan_win" key="stockholder_zhan_win" render={formatOpenedMoney} />
                        <Column className="stockholderBetColumn" title="股东输赢" dataIndex="stockholder_earn" key="stockholder_earn" render={formatOpenedMoney} />
                    </>
                }

                {context.user.type === UserType.admin &&
                    <>
                        <Column title="玩家" dataIndex="user_name" key="user_name" />
                        <Column title="玩家输赢" dataIndex="player_earn" key="player_earn" render={formatOpenedMoney} />
                        <Column className="agentBetColumn" title="代理名" dataIndex="proxy_name" key="proxy_name" />
                        <Column className="stockholderBetColumn" title="股东名" dataIndex="stockholder_name" key="stockholder_name" />
                        <Column className="agentBetColumn" title="代理占%" dataIndex="proxy_zhan_percent" key="proxy_zhan_percent" />
                        <Column className="stockholderBetColumn" title="股东占%" dataIndex="stockholder_zhan_percent" key="stockholder_zhan_percent" />
                        <Column className="agentBetColumn" title="代理水" dataIndex="proxy_rebate" key="proxy_rebate" render={formatOpenedVal} />
                        <Column className="stockholderBetColumn" title="股东水" dataIndex="stockholder_rebate" key="stockholder_rebate" render={formatOpenedMoney} />
                        <Column title="庄家水" dataIndex="banker_rebate" key="banker_rebate" render={formatOpenedMoney} />
                        <Column className="agentBetColumn" title="代理输赢" dataIndex="proxy_earn" key="proxy_earn" render={formatOpenedMoney} />
                        <Column className="stockholderBetColumn" title="股东输赢" dataIndex="stockholder_earn" key="stockholder_earn" render={formatOpenedMoney} />
                        <Column title="庄家占输赢" dataIndex="banker_zhan_win" key="banker_zhan_win" render={formatOpenedMoney} />
                        <Column title="庄家输赢" dataIndex="banker_earn" key="banker_earn" render={formatOpenedMoney} />
                    </>
                }
                {context.user.type === UserType.adminShare &&
                    <>
                        <Column title="玩家输赢" dataIndex="player_earn" key="player_earn" render={formatOpenedMoney} />
                        <Column title="庄家占" dataIndex="banker_zhan" key="banker_zhan" render={formatOpenedMoney} />
                        <Column title="庄家反水" dataIndex="banker_rebate" key="banker_rebate" render={formatOpenedMoney} />
                        <Column title="庄家占输赢" dataIndex="banker_zhan_win" key="banker_zhan_win" render={formatOpenedMoney} />
                        <Column title="庄家输赢" dataIndex="banker_earn" key="banker_earn" render={formatOpenedMoney} />
                    </>
                }
            </Table>
        </>
    )
}

export default BetList