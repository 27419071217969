import { useState, useEffect, useRef } from 'react';
import '../../App.css';
import moment from 'moment';

import apis from '../../apis';
import { StockInfo } from '../../utils/types';
import StocksList from './StocksList';


function StocksAutoRefresh({limit}:{limit?: number}) {
  let l = limit || 20
  const [stocksdata, setStocksdata] = useState<StockInfo[]>([]);
  const dataRef = useRef<StockInfo[]>();
  useEffect(() => {
    dataRef.current = stocksdata;
  }, [stocksdata])


  const doSearch = async () => {
    let startTimestamp = 0;
    if(dataRef.current && dataRef.current.length > 0){
      startTimestamp = moment(dataRef.current[0].pub_date).unix()+1
    }
    // 发送查询请求
    const res = await apis.getStocks(startTimestamp, 0, l)
    if(res.failed){
      console.error(res.error)
      return
    }
    if(res.data.length > 0){
      setStocksdata(res.data.concat(dataRef.current || []).slice(0,l))
    }
  };


  useEffect(() => {

    const intervalId = setInterval(() => {
      doSearch()
    }, 5000); // 每5秒发送一次查询请求
    doSearch();

    // 清理函数，在组件卸载时取消定时器
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <StocksList stocksdata={stocksdata} pagination={false}></StocksList>
  );
}

export default StocksAutoRefresh;
