import { Table } from "antd";
import { BillDetail } from "../utils/types";
import Column from "antd/es/table/Column";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "./user/AuthContext";
import apis from "../apis";
import { FormatMoney } from "./Utils";


function Bills() {
    const context = useContext(UserContext)

    const [tabledata, setTabledata] = useState<BillDetail[]>([]);

    const updateData = async ()=>{
        const res = await apis.getBills(context.user.ID)
        if(res.failed){
            console.error("获取账单失败")
            return
        }
        setTabledata(res.data)
    }
    const formatBillMoney = (v: string, r:BillDetail) => {
        if(r.type === 'reduce'){
            v = '-' + v
        }
        return FormatMoney(v)
    }

    useEffect(()=>{
        updateData()
    },[])
    return (
        <Table dataSource={tabledata} rowKey={record => record.id} scroll={{x: 'max-content'}} >
            <Column title="时间" dataIndex="created_at" key="created_at" render={(t) => moment.utc(t).format('MM-DD HH:mm:ss')} />
            {/* <Column title="类型" dataIndex="type" key="type" render={(t) => t=="add"?"增加":"减少"} /> */}
            <Column title="金额" dataIndex="value" key="value"  render={formatBillMoney}/>
            <Column title="旧分数" dataIndex="old_points" key="old_points" />
            <Column title="新分数" dataIndex="new_points" key="new_points" />
            <Column title="操作者" dataIndex="update_by_name" key="update_by_name" />
            {/* <Column title="用户名" dataIndex="user_name" key="user_name" /> */}
        </Table>
    )
}

export default Bills