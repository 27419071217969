const reloginEvt = new Event("relogin")

function Relogin(){
    window.dispatchEvent(reloginEvt)
}
function AddReloginListener(f: (e: any)=>void){
    window.removeEventListener("relogin", f);
    window.addEventListener("relogin", f);
}

function RemoveReloginListener(f: (e: any)=>void){
    window.removeEventListener("relogin", f);
}

export default {
    AddReloginListener,
    RemoveReloginListener,
    Relogin,
}