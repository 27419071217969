import { useContext } from 'react';
import { Button, Form, Input, InputNumber, FormProps, App } from 'antd';
import apis from '../../apis';
import { UserInfo, UserType } from '../../utils/types';
import { UserContext } from './AuthContext';
import '../../App.css';

function UserAddModal({onOk}:{onOk: ()=>any}) {
    const { message, modal } = App.useApp();
    const context = useContext(UserContext);
    const titleType = () => {
        let str = "用户"
        if (context.user.type === UserType.admin) {
            str = "股东"
        }
        if (context.user.type === UserType.stockholder) {
            str = "代理"
        }
        return str
    }
    // const [usereditForm] = Form.useForm();
    const [newuserForm] = Form.useForm();

    const submit = async () => {
        const values = await newuserForm.validateFields();
        values.type = context.user.type + 1;
        values.parent_id = context.user.ID;
        const res = await apis.addUser(values)
        if (res.failed) {
            message.error("添加用户失败:" + res.error)
            return
        }
        onOk()
    };

    const checkUserName = async()=>{
        const values = await newuserForm.getFieldsValue();
        const res = await apis.checkUserName(values)
        if (res.failed) {
            message.error("检查失败:" + res.error)
            return
        }
        message.success("用户名可用")
    }


    const content =
        <Form
            form={newuserForm}
            // name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            autoComplete="off"
        >
            <Form.Item<UserInfo>
                label="用户名"
                name="name"
                rules={[{ required: true, message: '请输入用户名' }]}
            >
                <Input />
            </Form.Item>
            <Button onClick={checkUserName}>检查用户名</Button>

            <Form.Item<UserInfo>
                label="密码"
                name="password"
                rules={[{ required: true, message: '请输入密码' }]}
            >
                <Input.Password />
            </Form.Item>

            {context.user.type === UserType.admin &&
                <Form.Item<UserInfo>
                    label="股份"
                    name="share"
                    initialValue={0}
                >
                    <InputNumber min={0} max={100} placeholder='最大100' style={{ width: '100%' }} />
                </Form.Item>
            }

            {context.user.type !== UserType.agent &&
                <Form.Item<UserInfo>
                    label="反水"
                    name="rebate"
                    initialValue={0}
                >
                    <InputNumber min={0} max={3} placeholder='最大3' style={{ width: '100%' }} />
                </Form.Item>
            }
            {context.user.type !== UserType.admin &&
                <Form.Item<UserInfo>
                    label="占成"
                    name="parent_zhan_cheng"
                    initialValue={0}
                >
                    <InputNumber min={0} max={ context.user.type === UserType.agent?30:50 } style={{ width: '100%' }} />
                </Form.Item>
            }
        </Form>

    const showModal = () => {
        modal.info({
            closable: true,
            title: "添加" + titleType(),
            okText: "提交",
            onOk: submit,
            content: content,
        })
    }

    return (
         <Button type="text" className='linkbtn' onClick={showModal}>添加{titleType()}</Button>
    )
}

export default UserAddModal;